import React,{useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../Components/Header';
import { Document ,Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5'
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Link } from 'react-router-dom';
import { apiURI } from "../../../config";
import { useDispatch, useSelector } from 'react-redux';
import logo from "../../../assests/img_guardian/guardian-logo.png"
// import IdeaValidate from './IdeaValidate';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Footer from '../../../Components/Footer';
import Draggable from 'react-draggable';
import { activeReviewTab } from '../../../reducers/ConstantSlice';
import RoastAnimation from './RoastAnimation/RoastAnimation';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


const DeckDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const walletAddress = useSelector((state) => state.constVar.wallet_address)
    const [connectWallet, setConnectWallet] = useState(false)
    // console.log("Connect Wallet 1234 ",connectWallet,walletAddress);
    const [show, setShow] = useState(false);
    const [validatePopup, setValidatePopup] = useState(false);
    const [idea,setIdea]= useState([]);
    const [invalidIdea, setInvalidIdea] = useState(false)

    const [pdfLoading, setPdfLoading] = useState(false)
    const [pdfData, setPdfData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [showTextarea, setShowTextarea] = useState(false);

    const handleToggleTextarea = () => {
      setShowTextarea(!showTextarea);
    };

    // const [isTouchScreen, setIsTouchScreen] = useState(false);

    // useEffect(() => {
    // setIsTouchScreen('ontouchstart' in window);
    // }, []);

    const handleShow = (e) => {
        if(connectWallet){
            alert("Please Connect your Wallet to Review 🫡")
        }
        else if(idea.guardian_level <= 0 ){
            alert("Become a Pawsifier to Review 🫡")
        }
        else{
            setShow(true)
        }
    };   


  const getIdeaData = async (value) => {
    try {
        console.log("Value",value)
      var query = `
        query GetIdeaDetails($walletAddress: String, $id: ID) {
            getIdea(walletAddress: $walletAddress, _id: $id) {
              idea_name
              idea_logo
              one_line_desc
              product_type
              category {
                value
              }
              pitchdeck_link
              whitepaper_link
              liveProduct_link
              competition_links
              revenueModel_type
              
              problem_statement
              problem_solution
              target_customers
              competition_reason
              validated
              guardian_level
            }
          }   
            `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          'x-power':process.env.REACT_APP_POWER_KEY,
          'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
          'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
          'x-range-name':process.env.REACT_APP_RANGE_NAME
        },
        body: JSON.stringify({
          query,
          variables: {
            id: id,
            walletAddress: walletAddress === "disconnect" ? "" : walletAddress
          }
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
            console.log("Idea",data.data.getIdea);
            if(data?.data?.getIdea === null){ 
                setInvalidIdea(true);
            }
            else if (data?.data?.getIdea) {
                await setIdea(data.data.getIdea)
                console.log("Data for Pdf Fetched")
                await fetchData(data.data.getIdea.pitchdeck_link)
            }
            
        });
    } catch (error) {
      console.log(error);
    }
  };

    useEffect(() => {
        if(walletAddress === "disconnect"){
            setConnectWallet(true);
            getIdeaData("disconnect");
        }else{
            getIdeaData(walletAddress)
            setConnectWallet(false);
        }

        // console.log("Redux Data :",userData)
    //   console.log(idea.length,"idea length")
    }, [walletAddress])
    
    
    // useEffect(() => {
    //   console.log("Use Effect Called for Loading DB")
      const openDatabase = () => {
        return new Promise((resolve, reject) => {
          const request = window.indexedDB.open('pdfs', 1);
          request.onerror = () => {
            reject(request.error);
          };
          request.onsuccess = () => {
            resolve(request.result);
          };
          request.onupgradeneeded = () => {
            const db = request.result;
            const objectStore = db.createObjectStore('pdfs');
          };
        });
      };
  
      const fetchData = async (value) => {
        try {
          const db = await openDatabase();
          const transaction = db.transaction(['pdfs'], 'readonly');
          const objectStore = transaction.objectStore('pdfs');
          const request = objectStore.get(`pdf-${id}.pdf`);
          request.onsuccess = () => {
            const data = request.result;
            if (data) {
              console.log("Pdf already exist")
              setPdfData(data);
              setPdfLoading(false)
            } else {
              console.log("Pdf needs to Be Downloaded")
              setPdfLoading(true)
              fetchPdf(value);
            }
          };
        } catch (error) {
          console.log(error);
        }
      };
  
      const fetchPdf = async (value) => {
        try {
          // const response = await fetch('https://dev.crsquare.finance/upload-98231675763830123.pdf');
          // console.log("Downloading from ",value)
          const response = await fetch(value);
          const blob = await response.blob();
          const db = await openDatabase();
          const transaction = db.transaction(['pdfs'], 'readwrite');
          const objectStore = transaction.objectStore('pdfs');
          const request = objectStore.put(blob, `pdf-${id}.pdf`);
          request.onsuccess = () => {
            setPdfData(blob);
            setPdfLoading(false)
          };
        } catch (error) {
          console.log(error);
        }
      };

    function onDocumentLoadSuccess({ numPages }) {
      console.log("Document Loaded",numPages , "Found")
      setNumPages(numPages);
      setPageNumber(1);
    }

    function changePage(offset) {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
  
    function previousPage() {
      changePage(-1);
    }
  
    function nextPage() {
      console.log()
      changePage(1);
    }
  
    useEffect(() => {
      if (pageNumber > numPages) {
        setPageNumber(numPages);
      }
    }, [pageNumber, numPages]);
  
    const backToReview2Earn = () => {
      dispatch(activeReviewTab("decks"))
      navigate("/review2earn")

    }

  return (
    <div>
        <Header />
        {invalidIdea ?
        <>
            <div  style = {{ justifyContent: "center",display: "flex",top: "30px",position: "relative"}}>
            Error 404 Invalid Product Details.
            </div> 
            <br />
            <p className="ans-validate" style = {{ justifyContent: "center",display: "flex",top: "30px",position: "relative"}}>
                <button onClick={backToReview2Earn} class="btn btn-primary btn-th">
                Back to Review2Earn</button>
            </p>&nbsp;

        </>
        
        :
        idea.length === 0 ? 
        <div  style = {{ justifyContent: "center",display: "flex",top: "30px",position: "relative"}}>
        Loading . . . 
        </div>
        :  
        <section className="section validate-page" style={{paddingTop:"15px"}}>
            <div className="container">
            <span class="list-inline-item mb-0">
                <button onClick={backToReview2Earn} class="btn btn-primary btn-th">
                <i class="uil uil-angle-double-left align-middle " title="twitter">
                </i>Back</button>
            </span>&nbsp;
            {idea.validated ? 
            <div style = {{ fontSize:"20px",}}>
            <p style = {{ display: "flex",justifyContent: "center",fontSize:"20px",color:"lime"}}>You have already Roasted this Deck</p>
            </div>
            :  ""}
            <div className='row border-bottom pb-2 mt-3'>
              <div className='col-lg-8'>
              <h5 className="text-muted qs">Project Name : <span className="ans-validate">{idea.idea_name}</span> </h5>
                        <h5 className="text-muted qs">One Line Description : <span className="ans-validate">{idea.one_line_desc}</span> </h5>
              </div>
              <div className='logo-div validate col-lg-4'> 
              <img src={idea.idea_logo} className="logo-validate" alt="" style={{float:"right" ,width:"17%"}}/>
                </div> 
            </div>
           

                {!pdfLoading ?
              <div>
              <div className='row'>
                <div className='col-lg-8 mt-2'>
                <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess} onLoadError ={(err)=> console.log(err)} >
                  <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}  className="pdf-view"/>
                </Document> 
                </div>

                {/* { !isTouchScreen && window.innerWidth >= 400 ? 
                <Draggable cancel="input" >
                  <div className='draggable-comment'> */}
                  {/* {showTextarea ? ( */}
                  {/* <FloatingLabel label="Comment this Slide" className="form-floating ">
                    <Form.Control as="textarea" placeholder="asd" name="comments" style={{ height: '200px', backgroundColor: "rgb(27 27 27 / 0%)", cursor: "crosshair", touchAction: "none" }} /> */}
                    {/* <button className='btn btn-outline-warning mt-2 '  onClick={handleToggleTextarea}>Save</button> */}
                
                  {/* </FloatingLabel> */}
{/* 
                ) : (
                  <button className='btn btn-outline-primary' style={{borderRadius:"100px",fontSize:"25px"}} onClick={handleToggleTextarea}>+</button>
                  )}    */}
                  {/* </div> 
                </Draggable>
                 : */}


                <div className='col-lg-4 mt-2'>
                  <div style={{height:"50%"}} >

                 <RoastAnimation  numPages={numPages} currentPage={pageNumber}/>
                  </div>

                <FloatingLabel label="Add Your Comments" className="form-floating mt-3" style={{height:"45%"}}>
                  <Form.Control as="textarea" placeholder="asd" name="comments" style={{ height:"100%",backgroundColor: "rgb(27 27 27 / 0%)" }} />
                </FloatingLabel>     
                  </div>

                  
              </div>
              </div>
              : 
              <div style={{justifyContent:'center'}} className="d-flex pt-2">
              <h5 className="text-muted ">Please Wait till your pdf is Loading</h5>  
              </div>
              }


              </div>
             
              <div style={{justifyContent:'center'}} className="d-flex pt-2">
            <p> Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</p>
            <br/>
            </div>
            <div style={{justifyContent:'center'}} className="d-flex">
          <button type="button" disabled={pageNumber <= 1} onClick={previousPage} className="btn btn-primary" >
          {"<"}
          </button> &nbsp;  
          <button 
                // style ={{display:allQuestionsAnswered ? "" : "none"  , float:"right"}}
                //  onClick={() => handleSubmit()}  
                className="btn btn-primary btn-th">Submit</button> <span style={{paddingRight:"4.5px"}}/>
        <button type="button" disabled={pageNumber >= numPages} onClick={nextPage} className="btn btn-primary">
          {">"}
        </button>
        {/* <hr /> */}
      </div>
      <br />
      <br />
      <br />
      {/* </div> */}

            {/* {idea.validated ?  "" : 
            <div className="validate-footer">
            <button className="btn btn-primary btn-th" onClick={handleShow}>Validate this Product</button>
            </div>
            } */}
        {/* <br /><br /><br /> */}

        
        { validatePopup ?                           
                <div className={` ${validatePopup ? 'modal fade show' : ''}`} style={{ display: validatePopup ? 'block' : 'none', background: '#000000C9' }}>
                <div className="modal-dialog modal-dialog-centered">
                 <div className="modal-content rounded shadow border-0">
        
                     <div className="modal-body py-5">
                     { <Link to="/review2earn" style={{position:"absolute", top:"0",right:"0"}}  className="btn btn-icon btn-close btn-cus-close"><i className="uil uil-times fs-4 text-dark"></i></Link> }
                        <div className="text-center">
                            <div className="mt-0">
                                <>
                                <h4>Congrats  🎉 </h4>
                                 <p className="head-gr-modal mb-0" style={{fontSize:"22px"}}>You just crushed that product review and unlocked  <span style={{color:"#00eb00"}}>50 xp</span> reward!</p>
                                 {/* <p className="head-gr-modal mb-0" style={{fontSize:"22px"}}>Gonna be groovy 🥳</p> */}
                                 <br />
                                 <div className="mt-4">
                                <Link to="/review2earn" className="btn btn-warning" >Evaluate more</Link>
                                </div>
                                </>
                             </div>
                         </div>
                     </div>
                  </div>
              </div>
            </div>
             : "" } 
        </section>
        }
    </div>
 
  )
}

export default DeckDetails


