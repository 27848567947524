import React from 'react'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'


import { useSelector } from "react-redux";


const UnderConstruction = () => {


    const walletAddress = useSelector((state) => state.constVar.wallet_address)

  return (

    <div>
        <Header />
        {walletAddress === "disconnect" ?  
        
        <div>
            <p className='lock-symbol btn btn-primary btn-th' > You're almost there, Plug in your wallet so we can keep the good times rolling. </p>
        </div> : "" }

        <section className="bg-home d-flex align-items-center bg-img-home2 " style={{ filter: walletAddress=== "disconnect" ? "blur(5px)" : ""}} >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-bold text-white title-dark" style={{fontSize:"30px !important"}}>🚀 Launching Soon - Get Ready for the Ride!</h5>
                            <h5 className="para-desc mx-auto text-white title-dark para-steps">Exciting things coming your way. Hold tight!
                            <br></br>Thanks for your patience!</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <Footer />
    </div>
  )
}

export default UnderConstruction