import React, { useState, useEffect } from 'react'
import { apiURI } from "../../config";
import { useSelector } from "react-redux";

//Page Components
import Header from '../../Components/Header'
import QuizPage from "./QuizPage"
import AlertPopup from '../../Components/AlertPopup';


const QuizHomePage = () => {

    // Redux Variables
    const walletAddress = useSelector((state) => state.constVar.wallet_address)
    const userData = useSelector((state) => state.constVar.user_data)
    
    // State Variables
    const [quizStarted, setQuizStarted] = useState(false);
    const [questions, setQuestions] = useState([]);
    
    const [alertOpen, setAlertOpen] = useState(false);
    const [message, setMessage] = useState('')

    

    // Api Function
    const getQuestion = async () => {
        try {
            var query = `
                query GetQuestionSet($walletAddress: String) {
                    getQuestionSet(walletAddress: $walletAddress) {
                        _id
                        index
                        question
                        options
                        answer
                        difficulty_level
                    }
                }    
                `;
            fetch(apiURI.URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                    'x-power':process.env.REACT_APP_POWER_KEY,
                    'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
                    'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
                    'x-range-name':process.env.REACT_APP_RANGE_NAME

                },
                body: JSON.stringify({
                    query,
                    variables: {
                        "walletAddress": "0x3b12"
                    }
                })
            })
                .then((response) => {
                    const json = response.json();
                    return json;
                })
                .then(async (data) => {
                    if (data?.data?.getQuestionSet) {
                        setQuestions(data.data.getQuestionSet);
                        setQuizStarted(!quizStarted);
                    }
                })

        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
    }, [userData])

    const QuizStart = () => {
        getQuestion()
    }


    return (

        quizStarted ?
            <><QuizPage quizStarted={quizStarted} setQuizStarted={setQuizStarted} questions={questions} /></>
            :
            <div>

                <Header />
                <AlertPopup open={alertOpen} setOpen={setAlertOpen} message={message} variant={"warning"}/>      
                <section className="bg-home d-flex align-items-center bg-img-home2" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="title-heading text-center">

                                    {userData.guardian_level === 0 ?
                                        <>
                                            <h4 className="heading fw-bold text-white title-dark">Stage 1</h4>
                                            <h5 className="para-desc mx-auto text-white title-dark para-steps">Looks like you are not a Pawsifer yet. <br></br>You can get started by taking the test </h5>
                                            <div className="mt-4 pt-2">

                                                {walletAddress === "disconnect" ? 
                                                
                                                <button onClick={() => {setMessage("Please Connect your Wallet to Begin the Test");setAlertOpen(true)}} className="btn btn-primary btn-th">
                                                    Start the test
                                                </button>
                                                    :
                                                <button onClick={() => QuizStart()} className="btn btn-primary btn-th">
                                                    Start the test
                                                </button> }
                                            </div>
                                        </>
                                        :
                                        <>
                                            <h4 className="heading fw-bold text-white title-dark">Stage 2</h4>
                                            <h5 className="para-desc mx-auto text-white title-dark para-steps">Are you ready to enhance your skills ?  <br></br>Take the Roarnado test to prove your knowledge and expertise.</h5>
                                            <div className="mt-4 pt-2">
                                                    <button onClick={() => {setMessage("Stage 2, Would be live soon. Thank you for your interest & patience.✌️!!");setAlertOpen(true)}} className="btn btn-primary btn-th">Start the test</button>
                                            </div>
                                        </>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
    )
}

export default QuizHomePage