import React from 'react'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Header from "../../Components/Header"
import Footer from "../../Components/Footer"

import News from "./News";
import Events from './Events';
import Blogs from "./Blogs"; 
import Videos from "./Videos";


const Resources = () => {
  return (
    <div>
        <Header />
        <div className="container mt-4">
            <h4 className="title mb-0">Resources</h4>
            <p className="mb-0">Resources to empower your Web3 know-how and supercharge your guardian skills to the max.</p>
        </div>
        
    <div className="container" style={{marginTop:"30px"}}>
        <div className="row ">
            <div className="col-lg-12"> 
            <Tabs defaultActiveKey="news" className="mb-3 tab-color" variant='tabs'>
            <Tab eventKey="news" title="News 📰">
                <News />    <br /><br /><br /><Footer />
            </Tab>
            <Tab eventKey="blogs" title="Blogs 📝">
                <Blogs /><br /><br /><br /><Footer />
            </Tab>
            <Tab eventKey="events" title="Events 🗓️" disabled><Events /></Tab>
            <Tab eventKey="videos" title="Videos ▶️" disabled><Videos /></Tab>
            </Tabs>

            </div>
        </div>
    </div>

    </div>
  )
}

export default Resources