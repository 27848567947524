import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./reducers/store";
import { ThirdwebProvider } from "@thirdweb-dev/react";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThirdwebProvider
        activeChain="polygon"
        dAppMeta={{
          name: "Guardian Dashboard",
          description:
            "Showcase your web3 expertise, earn airdrops, rewards and early access to innovative dapps",
          logoUrl:
            "https://dashboard.guardian.crsquare.finance/static/media/guardian-logo.1ee941cdbca221ffef4a.png",
          url: "https://dashboard.guardian.crsquare.finance/",
          isDarkMode: true,
        }}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThirdwebProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
