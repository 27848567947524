import React,{useState,useEffect} from "react";
import { gql, useMutation } from '@apollo/client';
import {useDispatch, useSelector} from "react-redux"
import {apiURI} from "../../config";
import { Link } from "react-router-dom";
import { user_data } from "../../reducers/ConstantSlice";

//Page Components 
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

// Icons
import profile from "../../assests/icons/profile.png"
import copy from "../../assests/icons/copy.png"
import edit from "../../assests/icons/edit.png"

import deals from "../../assests/icons/deal.png";
import ideas from "../../assests/icons/idea.png"
import projects from "../../assests/icons/rocket.png"
import milestones from "../../assests/icons/mission.png"
import wcr2 from "../../assests/icons/wcr2.png"

import lion from "../../assests/icons/lion2.gif"

// Bootstrap Components
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ProgressBar } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Spinner from 'react-bootstrap/Spinner';

//MUI Components
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import Compressor from 'compressorjs';

const Profile = () => {

  // Upload File Query 
  const UPLOAD_FILE = gql`
        mutation SingleUpload($file: Upload!, $input: ProjectIdInput) {
            singleUpload(file: $file, input: $input) {
              filename
              mimetype
              encoding
              url
              filepath
              ext
            }
          } `;

  const [uploadDocumentAsync] = useMutation(UPLOAD_FILE, {onCompleted: async () => {} })
  const hiddenProfileInput = React.useRef(null);

  // React Variables 
  const walletAddress = useSelector((state) => state.constVar.wallet_address)
  const userData = useSelector((state) => state.constVar.user_data)
  const dispatch = useDispatch();

  // State Variables
  const [popUp, setPopUp] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [xpLevel, setXpLevel] = useState(0);
  const XP_LEVELS = [0, 100, 200, 500, 1000, 2000, 5000, 7500, 10000];

  const [confirmation, setConfirmation] = useState(false);
  const [minting, setMinting] = useState(false)
  const [transferred,setTransferred] = useState(false)
  const [txn_hash, setTxn_hash] = useState('');
  
    
  // Progress Bar 
  const calculateLevel = () => {

    let left = 0;
    let right = XP_LEVELS.length - 1;
  
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      if (userData.total_xp >= XP_LEVELS[mid]) {
        setXpLevel(mid);
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }
  
  }
  //SnackBar 
  const [state, setState] = React.useState({
      open: false,
      vertical: 'top',
      horizontal: 'right',
    });
  const { vertical, horizontal, open } = state;

  //Pagination Table

  const [transactions,setTransactions] = useState([]);
  // const data = [ { Date: "23/10/2022", Name: "Duck Rocket", Category: "Idea", Reward: 50 },]; // Sample Api Data
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;
  const totalPages = Math.ceil(transactions.length / entriesPerPage);
  const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };
  const getCurrentEntries = () => { 
    // console.log(transactions,"Transactions")
        const startIndex = (currentPage - 1) * entriesPerPage;
        const endIndex = startIndex + entriesPerPage;
        return transactions.slice(startIndex, endIndex);
      };


  //Profile Picture Change Function 
  const handleProfile = (event) => {
    hiddenProfileInput.current.click();
  }

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const compressedFile = await new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.1,
        success(result) {
          // console.log("Compressed Success",result);
          resolve(result);
        },
        error(err) {
          console.log(err.message);
          reject(err);
        },
      });
    });
      if (compressedFile && (compressedFile.type === 'image/png' || compressedFile.type === 'image/jpeg' || compressedFile.type === 'image/jpg' || compressedFile.type === 'image/heic' || compressedFile.type === 'image/heif')) {
        let fileLink = await uploadDocumentAsync({
            variables: {file:compressedFile, input: { project_id: '' } }
          });

      setProfileImageUrl(fileLink.data.singleUpload.filepath)

      if(userData._id !== ""){
        updateProfilePic(userData._id,fileLink.data.singleUpload.filepath);
      }
    } 
  }

  const copyToClipboard = (event) => {
   navigator.clipboard.writeText(walletAddress);
  }

 //Claim wCR2 Tooltip
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>  Minimum 100 wCR2 Required </Tooltip>
  )

  // Snack Bar Components
  
  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  })

  const transferRewards = async (value) => {
    setMinting(true);
    try{
      var query = `
      mutation Mutation($input: ClaimRewardInput) {
        claimRewards(input: $input)
        }
          `;
      fetch(apiURI.URL, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Accept: "application/json",
              'x-power':process.env.REACT_APP_POWER_KEY,
              'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
              'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
              'x-range-name':process.env.REACT_APP_RANGE_NAME

          },
          body: JSON.stringify({
              query,
              variables: {
                "input": {
                "wallet_address": userData.wallet_address,
                "amount": value
                }
                }
              })
          })
          .then((response) => {
              const json = response.json();
              return json;
          })
          .then(async (data) => {
            console.log("Transferred, Api call to set the hash",data.data.claimRewards)
            setTxn_hash(data.data.claimRewards)
            setTransferred(true)
          })
      
      } 
    catch (error) {
      console.log(error)
      }
  }

  const handleClaimClose = async() => {
    await getGuardian()
    setTxn_hash("")
    setTransferred(false)
    setMinting(false)
    setConfirmation(false);
  }


// API Calls
    // Updating Profile Picture to DB
  const updateProfilePic = async (_id,imageURL) =>{
    try{
      var query = `
      mutation UpdateGuardian($id: ID, $input: GuardianInput) {
        updateGuardian(_id: $id, input: $input) {
          _id
          profile_picture
        }
      }
          `;
      fetch(apiURI.URL, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Accept: "application/json",
              'x-power':process.env.REACT_APP_POWER_KEY,
              'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
              'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
              'x-range-name':process.env.REACT_APP_RANGE_NAME

          },
          body: JSON.stringify({
              query,
              variables: {
                "id": _id,
                "input": {
                  "profile_picture": imageURL
                }
              }
              })
          })
          .then((response) => {
              const json = response.json();
              return json;
          })
          .then(async (data) => {
              // alert("Profile Pic Updated Successfully")
          })
      
      } 
    catch (error) {
      console.log(error)
      }
    }
  // Get Latest User Details
  const getGuardian = async () => {
    try{
        var query = `
        query Query($walletAddress: String) {
          getAllTransactions(walletAddress: $walletAddress) {
            _id
            transaction_name
            category
            reward
            wCR2_reward
            createdAt
            guardian {
              _id
              wallet_address
              profile_picture
              guardian_level
              total_xp
              unclaimed_rewards
              claimed_rewards
              total_rewards
              ideas_reviewed
              projects_reviewed
              deals_reviewed
              milestone_reviewed
            }
            wallet_address
          }
        }
            `;
        fetch(apiURI.URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                'x-power':process.env.REACT_APP_POWER_KEY,
                'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
                'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
                'x-range-name':process.env.REACT_APP_RANGE_NAME
              },
            body: JSON.stringify({
                query,
                variables: {
                      "walletAddress": walletAddress
                  }
                })
            })
            .then((response) => {
                const json = response.json();
                return json;
            })
            .then(async (data) => {
              // console.log("Data Transaction" , data.data.getAllTransactions)
                if(data?.data?.getAllTransactions !== null){
                  const txn_data = data.data.getAllTransactions.map((value, index) => {
                    const timestamp = parseInt(value.createdAt);
                    const date = new Date(timestamp);
                    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
                    return {
                      Name: value.transaction_name,
                      Category: value.category,
                      Reward: value.reward,
                      wCR2 : value.wCR2_reward,
                      Date:  date.toLocaleDateString('en-IN', options),
                    };
                  });
                  // console.log("date",).toLocaleDateString()
                  setTransactions(txn_data);
                  dispatch(user_data(data.data.getAllTransactions[0].guardian));
                  setProfileImageUrl(data.data.getAllTransactions[0].guardian.profile_picture)
                  calculateLevel();
                }
                else{
                  setPopUp(true);
                }
            })
        } 
    catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
      if(walletAddress === "disconnect"){
        setPopUp(true)
      }else{
        getGuardian();
      }
    }, [])

  return (
    <div>

      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} onClose={handleClose}
        message="I love snacks" key={vertical + horizontal} >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Copied Successfully
        </Alert>
      </Snackbar>

      <Header />

      
      {popUp ? 
        <div>
          <Link to="/quiz" className="lock-symbol btn btn-primary btn-th">
            Looks like you are not a Pawsifer yet. You can get started by taking the test
          </Link>
        </div> : ""}

      <div style={{ filter: popUp ? "blur(7px)" : "" }}>
        
      <section className="bg-profile d-table w-100 bg-primary">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card public-profile border-0 rounded shadow" style={{ zIndex: "1" }} >
                  <div className="card-body" style={{paddingTop: "20px"}}>
                    <div className="row align-items-center">
                      {/* Profile Pic */}
                      <div className="col-lg-2 col-md-3 text-md-start text-center" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <img src={profileImageUrl === ("" || null) ? profile : profileImageUrl}
                          className={`avatar avatar-large rounded-circle shadow d-block mx-auto ${isHovered ? "blurred" : ""}`} alt=""/>
                          {isHovered && (
                            <div onClick={handleProfile}>
                              <img style={{ width: "37px", height: "37px", position: "absolute", left: "38%", marginTop: "-25%", cursor:"pointer" }}
                                src={edit} className="avatar avatar-large rounded-circle shadow d-block mx-auto " alt="edit_icon" />
                            </div>)}
                            
                            <input type="file" ref={hiddenProfileInput} onChange={(e) => onFileChange(e)} style={{ display: 'none' }} />
                      </div>

                      <div className="col-lg-10 col-md-9">
                        <div className="row align-items-end">
                          <div className="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                            <h3 className="title mb-0">
                              {walletAddress.slice(0, 4)}...{walletAddress.slice(-3)}&nbsp;&nbsp;
                              <span style={{ cursor: "pointer" }} onClick={copyToClipboard} >
                                <img  onClick={handleClick({ vertical: "top", horizontal: "right", })} style={{ width: "20px" }} src={copy} alt="copy-icon" />
                              </span>
                            </h3>

                            {/* PROGRESS BAR */}
                            <div style={{ display: "flex" }} className="progress-xp-bar">
                              <small className="text-muted h6 me-2">Level {xpLevel}</small>
                              <ProgressBar
                                animated variant="warning"
                                min={0} max={XP_LEVELS[xpLevel + 1]}
                                now={userData.total_xp} label={`${userData.total_xp} xp `}
                                style={{ width: "50%", alignItems: "end",marginTop: "4px" }}/>
                              <small className="text-muted h6 me-2">&nbsp; Level {xpLevel + 1}</small>
                            </div>
                              <small className="text-muted h6 me-2">{XP_LEVELS[xpLevel + 1] - userData.total_xp} Xps Need to Rank Up</small>
                          </div>

                          {/* GIF IMAGE */}

                          <div className="col-md-5 text-md-end text-center">
                            <div className="pb-0 text-center" style={{display: "flex",justifyContent: "center"}}>
                              <a href="https://opensea.io/assets/matic/0xe26881573e89bccc74b2e330fd0e44fe49eb4a82/0" target="_blank">
                              <img
                                src={lion} className="avatar avatar-small rounded  d-block mx-auto "
                                alt="" style={{ height: "250px", width: "auto" }} />
                              </a>
                              <div style={{position:"absolute",top: "78%"}}>
                                  <h5 className="mb-0">You are a Pawsifer</h5>
                                  <Link to="/quiz"> Become a Roarnado </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section mt-60">
          <div className="container mt-lg-3">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="border-bottom pb-4 border-top">
                  <div className="row">
                    <div className="col-md-12 mt-4 pt-2 pt-sm-0">
                      <div className="row">

                        <div className="col-md-4">
                          <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow mt-0">
                            <img src={wcr2} className="avatar " alt=""  style={{height: "45px"}}/>
                              <div className="flex-1 content ms-3">
                                <h4 className="title mb-0">
                                  {userData.total_rewards} wCR2
                                </h4>
                                <p className="text-muted mb-0">Total Rewards</p>
                              </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow mt-0">
                          <img src={wcr2} className="avatar " alt=""  style={{height: "45px"}}/>
                              <div className="flex-1 content ms-3">
                                <h4 className="title mb-0">
                                  {userData.claimed_rewards} wCR2
                                </h4>
                                <p className="text-muted mb-0">Rewards Claimed</p>
                              </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow mt-0">
                          <img src={wcr2} className="avatar " alt=""  style={{height: "45px"}}/>
                              <div className="flex-1 content ms-3">
                                <h4 className="title mb-0">
                                  {userData.total_rewards - userData.claimed_rewards} wCR2
                                </h4>
                                <p className="text-muted mb-0">Rewards UnClaimed</p>
                              </div>
                          </div>
                        </div>

                      </div>
                    </div>  

                    <div className="col-md-12 mt-4 pt-2 pt-sm-0">
                      <div className="row">
                        
                        <div className="col-md-3">
                          <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow mt-0">
                            <img src={ideas} className="avatar avatar-ex-sm" alt="" />
                            <div className="flex-1 content ms-3">
                              <h4 className="title mb-0">
                                {userData.ideas_reviewed}
                              </h4>
                              <p className="text-muted mb-0">Products Reviewed</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 ">
                          <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow mt-0">
                            <img src={projects} className="avatar avatar-ex-sm" alt="icon" />
                            <div className="flex-1 content ms-3">
                              <h4 className="title mb-0">
                                {userData.projects_reviewed}
                              </h4>
                              <p className="text-muted mb-0">Projects Validated</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 ">
                          <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow mt-0">
                            <img src={deals} className="avatar avatar-ex-sm" alt="icon" />
                            <div className="flex-1 content ms-3">
                              <h4 className="title mb-0">
                                {userData.deals_reviewed}
                              </h4>
                              <p className="text-muted mb-0">Deals Validated</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 ">
                          <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow mt-0">
                            <img src={milestones} className="avatar avatar-ex-sm" alt="icon" />
                            <div className="flex-1 content ms-3">
                              <h4 className="title mb-0">
                                {userData.milestone_reviewed}
                              </h4>
                              <p className="text-muted mb-0">Milestone's Validated</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                {/* Transaction Table  */}

                <div className="row">
                  <div className="col-md-12 mt-4 pt-2">
                    <div className="col-12 mt-0 pt-2">
                      <div className="component-wrapper rounded shadow">
                        <div className="d-flex justify-content-between border-bottom">
                          <div className="p-4">
                            <h5 className="title mb-0">Your Transaction's </h5>
                          </div>
                          <div className="p-4">
                          {userData.total_rewards >=  100 ? 
                          <Button variant="primary" onClick={() => setConfirmation(true)}>Claim Rewards</Button> 
                          
                          : <OverlayTrigger placement="top" delay={{ show: 150, hide: 150 }}  overlay={renderTooltip} >
                              <Button variant="primary" >Claim Rewards</Button>
                            </OverlayTrigger>}
                          </div>
                        </div>
                        
                        {/* Table  */}
                        <div className="p-5 text-center ">
                          <div className="table-responsive bg-white shadow rounded table-mobile">
                            <table className="table table-hover mb-0 table-center">
                              <thead>
                                <tr>
                                  <th scope="col" className="border-bottom"> S.No </th>
                                  <th scope="col" className="border-bottom"> Date </th>
                                  <th scope="col" className="border-bottom"> Name </th>
                                  <th scope="col" className="border-bottom"> Category </th>
                                  <th scope="col" className="border-bottom"> Reward </th>
                                  <th scope="col" className="border-bottom"> wCR2 Earned </th>
                                </tr>
                              </thead>
                              <tbody>
                                {getCurrentEntries().map((value, index) => (
                                  <tr style={{}}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{value.Date}</td>
                                    <td>{value.Name}</td>
                                    <td>{value.Category}</td>
                                    <td >
                                      {value.Reward === 0 ? 
                                      <span style={{ color: "#04ff20" }} >-</span>
                                      :<span style={{ color: "#04ff20" }}> + {value.Reward} xp </span>} 
                                    </td>
                                    <td >
                                      {value.wCR2 === null ? 
                                      <span style={{ color: "#ff00f5" }} >-</span>:
                                      value.Category === "Transfer" ? 
                                      <span style={{ color: "red" }} > - {value.wCR2} wCR2</span>
                                      :<span style={{ color: "#ff00f5" }}> + {value.wCR2} wCR2</span>} 
                                    </td>
                                  </tr>
                                ))}

                              </tbody>
                            </table>
                          </div>

                          {totalPages === 1 ? "" :

                            <Pagination style={{ float: "right", marginTop: "10px", paddingBottom: "5px",}} >
                              {Array.from({ length: totalPages }).map( (_, index) => (
                                
                                  <Pagination.Item key={index} active={currentPage === index + 1} onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                  </Pagination.Item>
                              ))}
                            </Pagination> }
                            
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Transfer wCR2 Pop-ups */}
      { confirmation ?                           
        <div className={` ${confirmation ? 'modal fade show' : ''}`} style={{ display: confirmation ? 'block' : 'none', background: '#000000C9' }}>
        <div className="modal-dialog modal-dialog-centered">
         <div className="modal-content rounded shadow border-0">

             <div className="modal-body py-5">
             {!minting ? <button style={{position:"absolute", top:"0",right:"0"}}  className="btn btn-icon btn-close btn-cus-close" onClick={() => {setConfirmation(false)}}><i className="uil uil-times fs-4 text-dark"></i></button>  : ""}
             {transferred ? <Link to="/profile" style={{position:"absolute", top:"0",right:"0"}}  className="btn btn-icon btn-close btn-cus-close" onClick={() => {handleClaimClose()}}><i className="uil uil-times fs-4 text-dark"></i></Link>  : ""}
             
                <div className="text-center">
                    <div className="mt-0">
                         
                        
                        {transferred ? 
                        <>
                        <h4>Rewards Summary</h4>
                        <br />
                        <p className=" head-gr-modal mb-0"> Transfer of <span className="#ff00f5">{userData.total_rewards - userData.claimed_rewards - 100} wCR2</span>  Successful 🥳</p>
                        <p className=" head-gr-modal mb-0" ><a href={`https://polygonscan.com/tx/${txn_hash}`} target="_blank">Click here</a> to view your transaction</p>
                          <div className="mt-4">
                          <Link to="/review2earn" className="btn btn-primary btn-th " >#Review2Earn</Link>
                          </div>
                        </>
                        : minting ? 
                        <>
                        <h4>Rewards Summary</h4>
                        <br />
                         <h5>Hooray, You've earned <span style={{color:"#ff00f5"}}> {userData.total_rewards - userData.claimed_rewards - 100} wCR2 Tokens </span> </h5>
                         <p className="head-gr-modal mb-0" style={{fontSize:"22px"}}>Please wait while we process your Transaction</p>
                         {/* <p className="head-gr-modal mb-0" style={{fontSize:"22px"}}>Gonna be groovy 🥳</p> */}
                         <br />
                         <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        </>
                        : 
                        
                        <>
                        <h4>Rewards Summary</h4>
                        <br/>
                        <div className ="d-flex flex-wrap ms-5">
                        <h6 className="pb-2">Total Unclaimed Rewards : <span>&nbsp;{userData.total_rewards - userData.claimed_rewards} <img src={wcr2} className="avatar " alt=""  style={{height: "25px"}}/> wCR2 Tokens  </span></h6>  
                        <h6 className="pb-2">Minimum Wallet Balance : <span>&nbsp;100 <img src={wcr2} className="avatar " alt=""  style={{height: "25px"}}/> wCR2 Tokens  </span></h6>  
                        <h6 className="pb-2">Available for withdrawal : <span>&nbsp;{userData.total_rewards - userData.claimed_rewards - 100} <img src={wcr2} className="avatar " alt=""  style={{height: "25px"}}/> wCR2 Tokens  </span></h6>  
                        <br />
                        <br />
                        <br />
                        </div>
                        { (userData.total_rewards - userData.claimed_rewards - 100) >= 100 ? 
                        <><p className="mb-0"> You can withdraw { (userData.total_rewards - userData.claimed_rewards - 100)  }  wCR2 Tokens  </p>
                        <div className="mt-4">
                            <button onClick={() =>transferRewards(userData.total_rewards - userData.claimed_rewards - 100) } className="btn btn-primary btn-th" >Claim Tokens</button>
                        </div></>
                        :
                        <><p className="mb-0"> You still need { 100 - (userData.total_rewards - userData.claimed_rewards - 100)  }  wCR2 Tokens to withdraw </p><br />
                        <div className="mt-4">
                            <Link to="/review2earn" className="btn btn-primary btn-th " >#Review2Earn</Link>
                        </div>
                        </>
                        }
                        {/* <p className=" mb-0" ><a href={`https://polygonscan.com/tx/`} target="_blank">Click here</a> to view your transaction</p> */}
                        </>}
                     </div>
                 </div>
             </div>
          </div>
      </div>
    </div>
     : "" } 
      <Footer />
    </div>
  );
};

export default Profile;
