import React from "react";
import { Link } from "react-router-dom";

// Page Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
// import { useEffect,useState } from "react";
// import { apiURI } from "../../config";
import { useSelector } from "react-redux";

// import { user_data } from "../../reducers/ConstantSlice";

const HomePage = () => { 

//   const [userData, setUserData] = useState();
//   const dispatch = useDispatch();

//   const getGuardian = async (walletAddress) => {
//     try{
//         var query = `
//         query Query($walletAddress: String) {
//           getGuardian(walletAddress: $walletAddress) {
//             _id
//             wallet_address
//             profile_picture
//             guardian_level
//             total_xp
//             unclaimed_rewards
//             claimed_rewards
//             total_rewards
//             ideas_reviewed
//             projects_reviewed
//             deals_reviewed
//             milestone_reviewed
//           }
//         }
//             `;
//         await fetch(apiURI.URL, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Accept: "application/json",
//                 'x-power':process.env.REACT_APP_POWER_KEY,
//                 'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
//                 'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
//                 'x-range-name':process.env.REACT_APP_RANGE_NAME
//             },
//             body: JSON.stringify({
//                 query,
//                 variables: {
//                       "walletAddress": walletAddress
//                   }
//                 })
//             })
//             .then((response) => {
//                 const json = response.json();
//                 return json;
//             })
//             .then(async (data) => {
//                 if(data?.data?.getGuardian){
//                   setUserData(data.data.getGuardian)
//                   dispatch(user_data(data.data.getGuardian))
//                 }
//             })
//         } 
//     catch (error) {
//         console.log(error)
//     }
//   }

// useEffect(() => {

//   async function getData () {
//     await getGuardian()
//   }
//   getData();

// }, [])



const userData = useSelector((state) => state.constVar.user_data)

  return (
    <div>
      <Header />
      <section className="bg-home d-flex align-items-center bg-img-home" >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h4 className="heading fw-bold text-white title-dark lp-head welcome-text">Welcome to Guardian</h4>
                             <h5 className="mx-auto text-white title-dark lp-para" style={{fontWeight: "400"}}>Showcase your web3 expertise, earn airdrops, rewards and early access to innovative dapps</h5>
                              <div className="mt-4 pt-2">
                              <br />
                              {userData.guardian_level <= 0 ? 
                                <Link to="/quiz" className="btn btn-primary btn-th" style={{color:"white"}}>
                                  <span className="" onMouseEnter={(e) => { e.currentTarget.className += " linear-wipe";}}
                                      onMouseLeave={(e) => {e.currentTarget.className = e.currentTarget.className.replace(" linear-wipe", "");}}>
                                      #Review2Earn
                                  </span>
                                </Link>
                                :
                                <Link to="/review2earn" className="btn btn-primary btn-th" style={{color:"white"}}>
                                <span className="" onMouseEnter={(e) => { e.currentTarget.className += " linear-wipe";}}
                                    onMouseLeave={(e) => {e.currentTarget.className = e.currentTarget.className.replace(" linear-wipe", "");}}>
                                    #Review2Earn
                                </span>
                              </Link>} 
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <Footer />
    </div>
  );
};

export default HomePage;



