import React,{useEffect,useState} from 'react'
import { Link, useNavigate  } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { apiURI } from '../config';




//React Bootstrap
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


//Icons
import GuardianLogo from "../assests/img_guardian/guardian-logo.png"
import metamask from "../assests/icons/metamask.png";
import coinbase from "../assests/icons/coinbase.png";
import walletconnect from "../assests/icons/walletconnect.png";
import logout from "../assests/icons/logout.png";
import copy from "../assests/icons/copy.png"
import user from "../assests/icons/user.png"

//ThirdWeb Connect Wallet
import { useAddress,useNetwork } from "@thirdweb-dev/react";
import { useConnect } from "@thirdweb-dev/react";
import { useCoinbaseWallet } from "@thirdweb-dev/react";
import { useDisconnect } from "@thirdweb-dev/react";
import { useMetamask } from "@thirdweb-dev/react";
import { useWalletConnect } from "@thirdweb-dev/react";


// Connect Wallet
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import { wallet_address,profile_icon, user_data } from '../reducers/ConstantSlice';
import AlertPopup from './AlertPopup';
var ethereumBlockies = require("ethereum-blockies");




const Header = () => {

    //Redux Variables 
    const walletAddress = useSelector((state) => state.constVar.wallet_address)
    const icon = useSelector((state) => state.constVar.profile_icon)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [alertOpen, setAlertOpen] = useState(false);
    const [message, setMessage] = useState('')



    //State Variables
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
      });
    const { vertical, horizontal, open } = state;
    
    // Thirdweb Connect Wallet State Variables
    const address = useAddress();
    const network = useNetwork();
    const [{ data }, connect] = useConnect();
    const connectCoinbase = useCoinbaseWallet();
    const disconnect = useDisconnect();
    const connectWithMetamask = useMetamask();
    const connectWithWalletConnect = useWalletConnect();
    const [addressConnected,setAddressConnected]= useState(false);

    // useEffect(()=>{
    // },[walletAddress])

    useEffect(() => {
        if (addressConnected && address ) {
                walletConnectFunctions()
        }
      }, [address, addressConnected, network]);

    /////////Connect Wallet //////////////    
    const disconnectWallet = () => {

        window.localStorage.setItem("walletAddress","disconnect");
        window.localStorage.setItem("icon","");
        dispatch(wallet_address("disconnect"))
        dispatch(profile_icon(""))
        dispatch(user_data({guardian_level: 0,
            total_xp: 0,
            unclaimed_rewards: 0,
            claimed_rewards: 0,
            total_rewards: 0,
            ideas_reviewed: 0,
            projects_reviewed: 0,
            deals_reviewed: 0,
            milestone_reviewed: 0}));
            disconnect()
        navigate('/');     
    }
    
    const walletConnectFunctions = () => {

        console.log("Connected wallet address:",address.toLowerCase());
        window.localStorage.setItem("walletAddress",address.toLowerCase());
            dispatch(wallet_address(address.toLowerCase()))
            getGuardian(address.toLowerCase())
            iconGenerator(address.toLowerCase());
            iconGenerator(address.toLowerCase());
    }




    const iconGenerator = (wallet) => {
      let image = ethereumBlockies
        .create({
          // All options are optional
          seed: `${wallet}`, // seed used to generate icon data, default: random
          // color: '#dfe', // to manually specify the icon color, default: random
          // bgcolor: '#aaa', // choose a different background color, default: random
          size: 15, // width/height of the icon in blocks, default: 8
          scale: 3, // width/height of each block in pixels, default: 4
          // spotcolor: '#000' // each pixel has a 13% chance of being of a third color,
          // default: random. Set to -1 to disable it. These "spots" create structures
          // that look like eyes, mouths and noses.
        }).toDataURL();
        window.localStorage.setItem("icon", image);
        dispatch(profile_icon(image));
    };

    const handleCoinBase = async () => {

        await connectCoinbase();
        setAddressConnected(true) 

        // const APP_NAME = 'Guardian Dashboard'
        // const APP_LOGO_URL = 'https://guardian.crsquare.finance/static/img-guardian/logo-guardian.png'
        // const DEFAULT_ETH_JSONRPC_URL = 'https://mainnet.infura.io/v3/0a38c92df0ee41fba804cd70dfebb0a9'
        // const DEFAULT_CHAIN_ID = 137
        // try {
        //     var coinbaseWallet = new CoinbaseWalletSDK({
        //     appName: APP_NAME,
        //     appLogoUrl: APP_LOGO_URL,
        //     darkMode: true
        //     })
        // const ethereum = coinbaseWallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID)
  
        // const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
        // window.localStorage.setItem("walletAddress",accounts[0]);
        // dispatch(wallet_address(accounts[0]))
        // getGuardian(accounts[0])
        // iconGenerator(accounts[0]);
        // iconGenerator(accounts[0]);
        // }
        // catch (err) {
        //     console.log(err);
        //  }
    }

    const handleMetaMask = async () => {
        
        await connectWithMetamask(({ chainId: "0x137" }));
        setAddressConnected(true) 

        // if (window.ethereum) {
        //     const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        //     window.localStorage.setItem("walletAddress",accounts[0]);
        //     dispatch(wallet_address(accounts[0]))
        //     getGuardian(accounts[0])
        //     iconGenerator(accounts[0]);
        //     iconGenerator(accounts[0]);
        //     } 
        // else {
        //     alert("Metamask Not Found")
        // }
    }

    const handleWalletConnect = async () => {
        await connectWithWalletConnect();
        setAddressConnected(true)   
    }

    const copyToClipboard = (event) => {
         // Select the text field
        navigator.clipboard.writeText(walletAddress);        
      };

    
    //API Functions 
    const getGuardian = async (walletAddress) => {
        try{
            var query = `
            query Query($walletAddress: String) {
              getGuardian(walletAddress: $walletAddress) {
                _id
                wallet_address
                guardian_level
                total_xp
                unclaimed_rewards
                claimed_rewards
                total_rewards
                ideas_reviewed
                projects_reviewed
                deals_reviewed
                milestone_reviewed
              }
            }
                `;
            fetch(apiURI.URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "application/json",
                    'x-power':process.env.REACT_APP_POWER_KEY,
                    'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
                    'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
                    'x-range-name':process.env.REACT_APP_RANGE_NAME
    
                },
                body: JSON.stringify({
                    query,
                    variables: {
                          "walletAddress": walletAddress
                      }
                    })
                })
                .then((response) => {
                    const json = response.json();
                    return json;
                })
                .then(async (data) => {
                    if(data?.data?.getGuardian){
                      dispatch(user_data(data.data.getGuardian))
                    }
                })
            } 
        catch (error) {
            console.log(error)
        }
      }

  return (
    <div className='header_scroll'>
        {/* <div className='banner text-center' >
        <p className='mb-0' style={{fontWeight:"600"}}>The <span style={{ fontWeight: "800" }}>IDEA JAM</span> event is live from 26th June to 5th July.
        <a href="http://dev.guardian.crsquare.finance/register" rel='noreferrer' target='_blank' className='project-link'> Create an Idea</a> and share as much as possible to show us the reach and credibility.</p>
        <p className='mb-0' style={{fontWeight:"600"}}>Eventually, you might get a spot in <span style={{ fontWeight: "800" }}>Pitch2 by GUARDIAN</span> and pitch to the top notch investors.</p>
        </div> */}

        <AlertPopup open={alertOpen} setOpen={setAlertOpen} message={message} variant={"success"}/>      
        <header id="topnav" className="defaultscroll sticky" >
            {/* <div className="container" style={{display:"none"}}>

                <Link to="/" className="logo">
                    <img src={GuardianLogo} height="24" className="logo-dark-mode" alt="" />
                </Link>                

                <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item ps-1 mb-0">
                        { walletAddress !== "disconnect" ?   
                        <>
                        <Dropdown>

                            <Dropdown.Toggle  style={{backgroundColor:"transparent",border:"solid 0px"}}>
                                <span className='btn btn-icon'>
                                    <img style={{width: "16",height: "16",borderRadius: "50%"}}src={icon} alt="profile" />
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>   
                                {/* COPY TO CLIPBOARD *
                                <Dropdown.Item onClick={copyToClipboard} >
                                    <div onClick={() => {setMessage("Copied Successfully");setAlertOpen(true)}}>
                                    <span style={{fontSize:"14px"}}>
                                        <img style={{width:"25px"}} src={copy} alt="copy-icon" />&nbsp; {walletAddress
                                          .slice(0, 6) + "..." + walletAddress.slice(-4)} 
                                    </span>
                                    </div>
                                </Dropdown.Item>
                                {/* PROFILE *
                                <Dropdown.Item as={Link} to="/profile">
                                    <span style={{fontSize:"14px"}}>
                                        <img style={{width:"25px"}} src={user} alt="user-icon" />&nbsp; Profile
                                    </span>
                                </Dropdown.Item>
                                {/* DISCONNECT *
                                <Dropdown.Item onClick={() => {disconnectWallet()}}><span style={{fontSize:"14px"}}><img style={{width:"25px"}} src={logout} alt="logout-icon" />&nbsp; Disconnect</span></Dropdown.Item>
                            
                            </Dropdown.Menu>
                        </Dropdown>

                        </>: 
                        <>
                        <Dropdown >

                            <Dropdown.Toggle className='btn btn-primary btn-th' > Connect Wallet </Dropdown.Toggle>
                            {/* CONNECT WALLET OPTIONS *
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {handleMetaMask()}}><span style={{fontSize:"14px"}}><img style={{width:"25px"}} src={metamask} alt="metamask-icon" />&nbsp; MetaMask</span></Dropdown.Item>
                                <Dropdown.Item onClick={() => {handleCoinBase()}}><span style={{fontSize:"14px"}}><img style={{width:"25px"}} src={coinbase} alt="coingbase-icon" />&nbsp; CoinBase</span></Dropdown.Item>
                                <Dropdown.Item onClick={() => {handleWalletConnect()}}><span style={{fontSize:"14px"}}><img style={{width:"25px"}} src={walletconnect} alt="walletConnect-icon" />&nbsp; Wallet Connect</span></Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>
                        </>}
                        
                    </li>
                </ul>

                <div id="navigation"> 
                
                    <ul className="navigation-menu">
                    
                        <li><Link  to="/under-construction" className="sub-menu-item" >Resources</Link></li>
                        
                        <li><Link  to="/quiz" className='sub-menu-item' >Level up</Link></li>

                        <li><Link  to="/review2earn" className="sub-menu-item linear-wipe" >#Review2Earn</Link></li>

                        <li><Link  to="/under-construction" className="sub-menu-item">Leaderboard</Link></li>

                    </ul>
                </div>
            </div> */}

    <div className='container'> 
    {window.innerWidth > 992 ?  
    <Link to="/" className="logo">
        <img src={GuardianLogo} height="24" className="logo-dark-mode" alt="" />
    </Link>   
    :
    ""
    }

    <div className='d-flex navbarContentsSize' style={{display:"none"}}>
            <Navbar collapseOnSelect expand="lg"  variant="dark" >
      <Container>
       
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{marginRight: '5px'}} />

        <Navbar.Collapse id="responsive-navbar-nav" >

          <Nav style={{justifyContent:"space-between"}} >
          {window.innerWidth <= 992 ?   
          <Navbar.Brand as={Link} to="/" bsPrefix='logo'><img src={GuardianLogo} height="24" className="logo-dark-mode" alt="logo" /></Navbar.Brand>
            : ""}
                    <ul className="navigation-menu">
                    
                        <li><Link  to="/resources" className="sub-menu-item" >Resources</Link></li>
                        
                        <li><Link  to="/quiz" className='sub-menu-item' >Level up</Link></li>

                        <li><Link  to="/review2earn" className="sub-menu-item linear-wipe" >#Review2Earn</Link></li>

                        <li><Link  to="/under-construction" className="sub-menu-item">Leaderboard</Link></li> 

                    </ul>
          </Nav>
        </Navbar.Collapse>
      </Container>
      </Navbar>

    <ul className="buy-button list-inline mb-0">
                    <li className="list-inline-item ps-1 mb-0">
                        { walletAddress !== "disconnect" ?   
                        <>
                        <Dropdown>

                            <Dropdown.Toggle  style={{backgroundColor:"transparent",border:"solid 0px"}}>
                                <span className='btn btn-icon'>
                                    <img style={{width: "16",height: "16",borderRadius: "50%"}}src={icon} alt="profile" />
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>   
                                {/* COPY TO CLIPBOARD */}
                                <Dropdown.Item onClick={copyToClipboard} >
                                <div onClick={() => {setMessage("Copied Successfully");setAlertOpen(true)}}>
                                    <span style={{fontSize:"14px"}}>
                                        <img style={{width:"25px"}} src={copy} alt="copy-icon" />&nbsp; {walletAddress.slice(0, 6) + "..." + walletAddress.slice(-4)}
                                    </span>
                                    </div>
                                </Dropdown.Item>
                                {/* PROFILE */}
                                <Dropdown.Item as={Link} to="/profile">
                                    <span style={{fontSize:"14px"}}>
                                        <img style={{width:"25px"}} src={user} alt="user-icon" />&nbsp; Profile
                                    </span>
                                </Dropdown.Item>
                                {/* DISCONNECT */}
                                <Dropdown.Item onClick={() => {disconnectWallet()}}><span style={{fontSize:"14px"}}><img style={{width:"25px"}} src={logout} alt="logout-icon" />&nbsp; Disconnect</span></Dropdown.Item>
                            
                            </Dropdown.Menu>
                        </Dropdown>

                        </>: 
                        <>
                        <Dropdown >
                            <Dropdown.Toggle className='btn btn-primary btn-th' style={{fontSize:"12px"}} > Connect Wallet </Dropdown.Toggle>
                            {/* CONNECT WALLET OPTIONS */}
                            <Dropdown.Menu style={{minWidth: "fit-content"}}>
                                <Dropdown.Item onClick={() => {handleMetaMask()}}><span style={{fontSize:"11px"}}><img style={{width:"20px"}} src={metamask} alt="metamask-icon" />&nbsp; MetaMask</span></Dropdown.Item>
                                <Dropdown.Item onClick={() => {handleCoinBase()}}><span style={{fontSize:"11px"}}><img style={{width:"20px"}} src={coinbase} alt="coingbase-icon" />&nbsp; CoinBase</span></Dropdown.Item>
                                <Dropdown.Item onClick={() => {handleWalletConnect()}}><span style={{fontSize:"11px"}}><img style={{width:"20px"}} src={walletconnect} alt="walletConnect-icon" />&nbsp; Wallet Connect</span></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </>}
                        
                    </li>
                </ul>
    </div>
    </div>


        </header>
    </div>

  )
}

export default Header




