import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
const userAdapter = createEntityAdapter();
const initialState = userAdapter.getInitialState({
  wallet_address: "",
  profile_icon: "",
  user_data: {
    _id:"",
    guardian_level: 0,
    total_xp: 0,
    unclaimed_rewards: 0,
    claimed_rewards: 0,
    total_rewards: 0,
    ideas_reviewed: 0,
    projects_reviewed: 0,
    deals_reviewed: 0,
    milestone_reviewed: 0,
    profile_picture:""
  },
  activeReviewTab:"TopRated"
});
export const constantSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    wallet_address(state, action) {
      state.wallet_address = action.payload;
      // console.log("redux data wallet ",action.payload);
    },
    profile_icon(state, action) {
      state.profile_icon = action.payload;
      // console.log("redux data icon ",action.payload);
    },
    user_data(state, action) {
      state.user_data = action.payload;
      // console.log("Redux Data Guardian In Redux ",action.payload);
    },
    activeReviewTab(state,action){
      state.activeReviewTab = action.payload
      console.log("Active Tab Redux",action.payload)
    }
  },
});

export const { wallet_address, profile_icon, user_data, activeReviewTab} = constantSlice.actions;

export default constantSlice.reducer;
