
import React,{useEffect, useState, useMemo} from 'react'

import "./RoastAnimation.css"


import pd_1 from "./images/pd-1.png";
import pd_2 from "./images/pd-2.png";

const RoastAnimation = ({numPages,currentPage}) => {

  //Calculate Pages Group
  const pagesGroup = useMemo(() => Math.ceil(numPages / 5), [numPages]);

  const [roastValue, setRoastValue] = useState(Array(pagesGroup).fill(undefined));
  const [average, setAverage] = useState(0);
  const currentGroup = Math.ceil(currentPage / 5);
  // console.log("currentPage",currentPage,"currentGroup",currentGroup,"Roast Value",roastValue[currentGroup])

  const handleRoastInput = (e) => {
    const newRoastValue = [...roastValue];
    newRoastValue[currentGroup] = e;
    setRoastValue(newRoastValue);
    const total = newRoastValue.reduce((acc, value) => {
      return value !== undefined ? acc + value : acc;
    }, 0);
    const avg = total / newRoastValue.filter((value) => value !== undefined).length || 0;
    setAverage(avg);
    // console.log("currentPage",currentPage,"currentGroup",currentGroup,"Roast Value",roastValue[currentGroup],"average",average)
  }

  useEffect(()=> {

  })

  useEffect(() => {
    // console.log("useEffect Called")
  }, [average])
  
  
  return (
    <div>
                    <div class="container">
                <div class="row ">
                {/* border-bottom pb-4 */}
                    <div class="col-lg-12 col-md-12">
                      <div class="rating-roast ">
              <div class="feedback">
                <div class="rating">
                  <input className='' type="radio" name="rating" id="rating-5" checked={roastValue[currentGroup] === 5} onChange={() => handleRoastInput(5)}/>
                  <label class="rating-5 rate " for="rating-5"></label>
                  <input type="radio" name="rating" id="rating-4" checked={roastValue[currentGroup] === 4 } onChange={() => handleRoastInput(4)}/>
                  <label class="rating-4 rate" for="rating-4"></label>
                  <input type="radio" name="rating" id="rating-3" checked={roastValue[currentGroup] === 3 } onChange={() => handleRoastInput(3)}/>
                  <label class="rating-3 rate" for="rating-3"></label>
                  <input type="radio" name="rating" id="rating-2" checked={roastValue[currentGroup] === 2 } onChange={() => handleRoastInput(2)}/>
                  <label class="rating-2 rate" for="rating-2"></label>
                  <input type="radio" name="rating" id="rating-1" checked={roastValue[currentGroup] === 1 } onChange={() => handleRoastInput(1)}/>
                  <label class="rating-1 rate" for="rating-1"></label>
                   
                   <div class="emoji">
                    <div class="emoji-wrapper">
                      {average === 0 ? 
                      <img src={pd_1} class="main-image-1" alt="roast-0" style={{width: "150px"}}  />
                      : average  <= 3 ? 
                      <img class="main-image" alt="roast-1" style={{width: "150px"}} src={pd_1} />
                      : 
                      <img class="main-image" alt="roast-2" style={{width: "150px"}} src={pd_2} />
                      }
{/* 

                      <img class="rating-3" alt="roast-3" style={{width: "150px"}} src={pd_2} />

                      <img class="rating-4" alt="roast-4" style={{width: "150px"}} src={pd_1}/>

                      <img class="rating-5" alt="roast-5" style={{width: "150px"}} src={pd_2} /> */}
                    </div>
                  </div> 

                </div>
              </div>
              </div>  
          </div>
          </div>
            </div>
    </div>


  )
}

export default RoastAnimation