
import React, { useState,useEffect } from 'react';
import { Document ,Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5'
// import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

// import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


const Events = () => {

  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {

    const openDatabase = () => {
      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open('pdfs', 1);
        request.onerror = () => {
          reject(request.error);
        };
        request.onsuccess = () => {
          resolve(request.result);
        };
        request.onupgradeneeded = () => {
          const db = request.result;
          const objectStore = db.createObjectStore('pdfs');
        };
      });
    };

    const fetchData = async () => {
      try {
        const db = await openDatabase();
        const transaction = db.transaction(['pdfs'], 'readonly');
        const objectStore = transaction.objectStore('pdfs');
        const request = objectStore.get('sample.pdf');
        request.onsuccess = () => {
          const data = request.result;
          if (data) {
            setPdfData(data);
          } else {
            fetchPdf();
          }
        };
      } catch (error) {
        console.log(error);
      }
    };

    const fetchPdf = async () => {
      try {
        const response = await fetch('https://dev.crsquare.finance/upload-98231675763830123.pdf');
        const blob = await response.blob();
        const db = await openDatabase();
        const transaction = db.transaction(['pdfs'], 'readwrite');
        const objectStore = transaction.objectStore('pdfs');
        const request = objectStore.put(blob, 'sample.pdf');
        request.onsuccess = () => {
          setPdfData(blob);
        };
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);


  function onDocumentLoadSuccess({ numPages }) {
    console.log("Document Loaded",numPages , "Found")
    setNumPages(numPages);
    setPageNumber(1);
  }
  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    console.log()
    changePage(1);
  }

  useEffect(() => {
    if (pageNumber > numPages) {
      setPageNumber(numPages);
    }
  }, [pageNumber, numPages]);

  if (!pdfData) {
    return <div>Loading...</div>;
  }

  return (
    <div >
      Events
      <div style={{justifyContent:'center'}} className="d-flex">
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
          className="btn btn-primary"
        >
          {"<"}
        </button>
        &nbsp;  
        <button
          type="button" 
          disabled={pageNumber >= numPages}
          onClick={nextPage}
          className="btn btn-primary"
        >
          {">"}
        </button>
        <hr />
      </div>
        <p>
          current Page Number - {pageNumber}
          <br />
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
        {/* url="https://dev.crsquare.finance/upload-6141669619822238.pdf"       */}
      <Document
          // file={{
          //   url:'https://dev.crsquare.finance/upload-6141669619822238.pdf'
          // }}
          file={pdfData}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError ={(err)=> console.log(err)}
      >
        <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}  className="pdf-view"/>

      </Document>
      <br />
      <br />
      <br />
    </div>
  )
}

export default Events

