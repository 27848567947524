import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Pagination } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import IdeaJamCard from "./IdeaJamCard";
import ShareComponent from "../../../Components/ShareComponent";


const All_IdeaJam = ({ allIdeas }) => {
  const navigate = useNavigate();
  const [sortMode, setSortMode] = useState("none"); // add sortMode state variable
  const [isDescending, setIsDescending] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [currentIdea, setCurrentIdea] = useState()

  const [sharePopup, setSharePopup] = useState(false);

  let sortedIdeas =
    sortMode === "name"
      ? [...allIdeas].sort((a, b) =>
          a.idea_name.localeCompare(b.idea_name, undefined, {
            sensitivity: "base",
          })
        )
      : sortMode === "type"
      ? [...allIdeas].sort((a, b) =>
          a.product_type.localeCompare(b.product_type, undefined, {
            sensitivity: "base",
          })
        )
      : allIdeas;

  sortedIdeas = isDescending ? sortedIdeas.reverse() : sortedIdeas;

  let filteredIdeas = sortedIdeas.filter((idea) =>
    idea.idea_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  var settings = {
    speed: 500,
    rows: filteredIdeas.length < 6 ? 1 : 2,
    slidesToShow: filteredIdeas.length <= 3 ? filteredIdeas.length : 3,
    swipeToSlide: true,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:  filteredIdeas.length <= 2 ? filteredIdeas.length : 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow:  filteredIdeas.length <= 2 ? filteredIdeas.length : 2,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots:true,
          dotsClass: "slick-dots slick-thumb",
          arrows: true,
          infinite: true,
        },
      },
    ],
  };

  const handleSharePopup = (e) => {
    setCurrentIdea(e)
    setSharePopup(true);
  }

  const copyToClipboard = (event) => {
   navigator.clipboard.writeText("Content to copy");        
 }


  return (
    <div>
      {/* <h4>Header {filteredIdeas.length}</h4> */}
      <div class="row pt-0">
        <div class="col-12">
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-cloud"
              role="tabpanel"
              aria-labelledby="pills-cloud-tab"
            >
              <div class="row pb-4 border-bottom">
                <div
                  className="mb-0 mt-4 all-ideas-text"                >
                  <h6 style={{ width: "150px" }}>All Products &nbsp;</h6>
                  <div className="all-ideas-text">
                    <input
                      className="input-search "
                      type="text"
                      placeholder="🔎 Search by Product name"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <h6 style={{ display: "flex", alignItems: "baseline" }}>
                    <button
                      className={`btn btn-link all-ideas-text-btn ${
                        sortMode === "name" ? "active" : ""
                      } `}
                      onClick={() => {
                        if (sortMode === "name") {
                          setIsDescending(!isDescending);
                        } else {
                          setIsDescending(false);
                          setSortMode("name");
                        }
                      }}
                      data-sort="type"
                    >
                      Sort by Name
                      {sortMode === "name" &&
                        (isDescending ? <span>▲</span> : <span>▼</span>)}
                    </button>
                    <span className="all-ideas-text-btn">|</span>
                    <button
                      className={`btn btn-link all-ideas-text-btn  ${
                        sortMode === "type" ? "active" : ""
                      } `}
                      onClick={() => {
                        if (sortMode === "type") {
                          setIsDescending(!isDescending);
                        } else {
                          setIsDescending(false);
                          setSortMode("type");
                        }
                      }}
                    >
                      Sort by Type
                      {sortMode === "type" &&
                        (isDescending ? <span>▲</span> : <span>▼</span>)}
                    </button>
                  </h6>
                </div>

                {filteredIdeas.length === 0 ?
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <h6  className="mt-5" style={{textAlign:"center"}}>No Product's Found Matching your Text</h6>
                  <br />
                  <br />


                </div>
                : 
                <Slider {...settings}>
                  {filteredIdeas.map((value, index) => {
                    return <IdeaJamCard  data={value} setSharePopup={handleSharePopup}/>;
                  })}
                </Slider>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      { sharePopup ?                           
                <div className={` ${sharePopup ? 'modal fade show' : ''}`} style={{ display: sharePopup ? 'block' : 'none', background: '#000000C9' }}>
                <div className="modal-dialog modal-dialog-centered">
                 <div className="modal-content rounded shadow border-0">
        
                     <div className="modal-body py-5">
                     { <button onClick={()=> setSharePopup(false)} style={{position:"absolute", top:"0",right:"0"}}  className="btn btn-icon btn-close btn-cus-close"><i className="uil uil-times fs-4 text-dark"></i></button> }
                            
                            <div className="mt-0 text-center">
                                <>
                                <h4>Share this Idea </h4>
                                <br />
                                  <span>
                                  Share as much as possible and show us the reach and credibility to get a spot in "Pitch2 by GUARDIAN" and pitch to the top notch investors.
                                  </span>
                                <br />
                                <br />
                                <ShareComponent currentIdea={currentIdea}/>
                                </>
                             </div>
                     </div>
                  </div>
              </div>
            </div>
             : "" } 
    </div>
  );
};

export default All_IdeaJam;
