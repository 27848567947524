import React,{useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../Components/Header';

import { Link } from 'react-router-dom';
import { apiURI } from "../../../config";
import { useDispatch, useSelector } from 'react-redux';
import logo from "../../../assests/img_guardian/guardian-logo.png"
import IdeaValidate from './IdeaValidate';
import Footer from '../../../Components/Footer';


import AlertPopup from '../../../Components/AlertPopup';
import { activeReviewTab } from '../../../reducers/ConstantSlice';


const IdeaDetail = () => {
    const { id } = useParams();
    const walletAddress = useSelector((state) => state.constVar.wallet_address)
    const [connectWallet, setConnectWallet] = useState(false)
    // console.log("Connect Wallet 1234 ",connectWallet,walletAddress);
    const [show, setShow] = useState(false);
    const [validatePopup, setValidatePopup] = useState(false);
    const [idea,setIdea]= useState([]);
    const [invalidIdea, setInvalidIdea] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [alertOpen, setAlertOpen] = useState(false);
    const [message, setMessage] = useState('')

    const handleClose = (e) => {
        setShow(false)};
    const handleShow = (e) => {
        // console.log("Handle Show," ,connectWallet)
        if(connectWallet){
            setMessage("Please Connect your Wallet to Review 🫡")
            setAlertOpen(true)
            // alert("Please Connect your Wallet to Review 🫡")
        }
        else if(idea.guardian_level <= 0 ){
            // alert("Become a Pawsifier to Review 🫡")
            setMessage("Become a Pawsifier to Review 🫡")
            setAlertOpen(true)
        }
        else{
            setShow(true)
        }
    };


    const showPopup = () =>{
        setValidatePopup(true);
    }

    const checkDisabledItems = (data) => {
        const disabledItems = [false,false,false,false,false,false,false];
        const properties = ['problem_statement', 'problem_solution','whitepaper_link','competition_reason'];
        
        for(let i = 0; i < properties.length; i++) {
        const property = properties[i];
        if(data[property] === "" || (Array.isArray(data[property]) && data[property][0] === "")) {
        disabledItems[i === 0 ? 1: i === 1 ? 2 : i=== 2 ? 3 :i=== 3 ? 6 : 8 ] = true;
        }
        }
        
        // console.log("Disabled Items", disabledItems);
        return disabledItems
        }
    
    const disabledItems = checkDisabledItems(idea)


  const getIdeaData = async (value) => {
    try {
        // console.log("Value",value)
      var query = `
        query GetIdeaDetails($walletAddress: String, $id: ID) {
            getIdea(walletAddress: $walletAddress, _id: $id) {
              idea_name
              idea_logo
              one_line_desc
              product_type
              category {
                value
              }
              pitchdeck_link
              whitepaper_link
              liveProduct_link
              competition_links
              revenueModel_type
              
              problem_statement
              problem_solution
              target_customers
              competition_reason
              validated
              guardian_level
            }
          }   
            `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          'x-power':process.env.REACT_APP_POWER_KEY,
          'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
          'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
          'x-range-name':process.env.REACT_APP_RANGE_NAME
        },
        body: JSON.stringify({
          query,
          variables: {
            id: id,
            walletAddress: walletAddress === "disconnect" ? "" : walletAddress
          }
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
            // console.log("Idea",data.data.getIdea);
            if(data?.data?.getIdea === null){
                setInvalidIdea(true);
            }
            else if (data?.data?.getIdea) {
                await setIdea(data.data.getIdea)
                await checkDisabledItems(data.data.getIdea);
            }
            
        });
    } catch (error) {
      console.log(error);
    }
  };

    useEffect(() => {
        if(walletAddress === "disconnect"){
            setConnectWallet(true);
            getIdeaData("disconnect");
        }else{
            getIdeaData(walletAddress)
            setConnectWallet(false);
        }

        // console.log("Redux Data :",userData)
    //   console.log(idea.length,"idea length")
    }, [walletAddress])
    
    const backToReview2Earn = () =>{
        dispatch(activeReviewTab("ideas"))
        navigate("/review2earn")
    }


  return (
    <div>
        <Header />
        {invalidIdea ?
        <>
            <div  style = {{ justifyContent: "center",display: "flex",top: "30px",position: "relative"}}>
            Error 404 Invalid Product Details.
            </div> 
            <br />
            <p className="ans-validate" style = {{ justifyContent: "center",display: "flex",top: "30px",position: "relative"}}>
                <button onClick={backToReview2Earn} class="btn btn-primary btn-th">
                Back to Review2Earn</button>
            </p>&nbsp;

            </>
        
        :
        idea.length === 0 ? 
        <div  style = {{ justifyContent: "center",display: "flex",top: "30px",position: "relative"}}>
        Loading . . . 
        </div>
        :  
        <section className="section validate-page">
            <div className="container">

                            
            <span class="list-inline-item mb-0">
                <button onClick={backToReview2Earn} class="btn btn-primary btn-th">
                <i class="uil uil-angle-double-left align-middle " title="twitter">
                </i>Back</button>
            </span>&nbsp;

            {idea.validated ? 
            <div style = {{ fontSize:"20px",}}>
            <p style = {{ display: "flex",justifyContent: "center",fontSize:"20px",color:"lime"}}>You have already Validated this Product</p>
            </div>
            :  ""}

                <div className="row border-bottom pb-4">
                    <div className="logo-div validate" >
                        <img src={idea.idea_logo} className="logo-validate" alt="" style={{float:"right"}}/>
                    </div>
                    <div className="col-lg-5 col-md-4 col-12">
                        <div className="features feature-primary">
                            <div className="content mt-4">
                                <h5 className="text-muted qs">Project Name : <span className="ans-validate">{idea.idea_name}</span> </h5>
                                <h5 className="text-muted qs">One Line Description : <span className="ans-validate">{idea.one_line_desc}</span> </h5>
                                <h5 className="text-muted qs">Product Type : <span className="ans-validate">{idea.product_type}</span> </h5>
                            </div>
                        </div>
                    </div>

                     <div className="col-lg-3 col-md-4 col-12">
                        <div className="features feature-primary">
                            <div className="content mt-4">
                                <h5 className="text-muted qs">Category : <span className="ans-validate">{idea.category ? `${idea.category[0].value}`  : "Not Provided"}</span> </h5>
                                <h5 className="text-muted qs">White Paper : 
                                {idea.whitepaper_link === "" ? <span className="ans-validate"> Not Applicable</span> :
                                <a href={idea.whitepaper_link} target="_blank" className="ans-validate" rel="noreferrer" > View </a> }
                                </h5>
                                
                                <h5 className="text-muted qs">Pitch Deck : 
                                {idea.pitchdeck_link === "" ? <span className="ans-validate"> Not Applicable</span> : 
                                <a href={idea.pitchdeck_link} target="_blank" className="ans-validate" rel="noreferrer"> View </a> }
                                </h5>
                            </div>
                        </div>
                    </div>  

                     <div className="col-lg-3 col-md-4 col-12">
                        <div className="features feature-primary">
                            <div className="content mt-4">
                                <h5 className="text-muted qs">Product : 
                                {idea.liveProduct_link === "" ? <span className="ans-validate"> Not Applicable</span> : 
                                <a href={idea.liveProduct_link} target="_blank" className="ans-validate" rel="noreferrer" > View Product</a>} 
                                </h5>
                                <h5 className="text-muted qs">Competition Links: <span className="ans-validate">{idea.competition_links ? <a href={idea.competition_links[0]} target="_blank" className="ans-validate" rel="noreferrer" >View Product</a>  : "Not Provided"}</span> </h5>
                                
                                {/* <h5 className="text-muted qs">Competition Links: <span className="ans-validate">{idea.competition_links ? 
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a className="ans-validate" >{idea.competition_links[0]}</a>  : "Not Provided"}</span> </h5> */}
                                {/* href={idea.competition_links[0]} target="_blank"  */}
                                <h5 className="text-muted qs">Revenue Module : <span className="ans-validate">{idea.revenueModel_type}</span> </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container mt-4 " style={{marginTop:"50px"}}>
                <div className="row align-items-center">
                    {/* <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"> */}
                        <div className="row border-bottom pb-2 ">
                            <div className="section-title">
                            <h4 className="title validate-head-ans">Problem Statement</h4>
                            <ul className="ul-vhs">
                            {idea.problem_statement[0] === "" ? "Not Provided" :
                                idea.problem_statement.map((value,index) => {
                                // return(<li>{value.length > 100 ? `${value.slice(0,100)}...` : value  }</li>)
                                return(<li>{value}</li>)
                                })
                            }
                            </ul>
                            </div>
                        </div>
                        <br />
                        <br />
                    {/* </div> */}
                    {/* <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"> */}
                    <div className="row border-bottom pb-2 mt-4">
                        <div className="section-title">
                            <h4 className="title validate-head-ans">Solution</h4>
                            <ul className="ul-vhs">
                            {idea.problem_solution[0] === "" ? "Not Provided" :
                            idea.problem_solution.map((value,index) => {    
                                // <li>Leading financial transparency to the Web3 community</li>
                                // return(<li>{value.length > 80 ? `${value.slice(0,80)}...` : value  }</li>)
                                return(<li>{value}</li>)
                                })
                            }
                            </ul>
                        </div>
                    </div>
                    <br />
                    <br />
                    
                {/* </div>
            </div> */}
{/* 
            <div className="container mt-100 mt-60">
                <div className="row align-items-center"> */}
                    {/* <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"> */}
                    <div className="row border-bottom pb-2 mt-4">
                        <div className="section-title">
                            <h4 className="title validate-head-ans">Target Audience</h4>
                            <ul className="ul-vhs">
                            {idea.target_customers[0] === "" ? "Not Provided" :
                            idea.target_customers.map((value,index) => {
                                // <li>Leading financial transparency to the Web3 community</li>
                                // return(<li>{value.length > 80 ? `${value.slice(0,80)}...` : value  }</li>)
                                return(<li>{value}</li>)
                                })}
                            </ul>
                        </div>
                        </div>
                    {/* </div> */}
                    {/* <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"> */}
                    <div className="row border-bottom pb-2 mt-4" >
                        <div className="section-title">
                            <h4 className="title validate-head-ans">Unique Selling Point</h4>
                            <ul className="ul-vhs">
                            {idea.competition_reason[0] === "" ? "Not Provided" :
                            idea.competition_reason.map((value,index) => {
                                // <li>Leading financial transparency to the Web3 community</li>
                                // return(<li>{value.length > 80 ? `${value.slice(0,80)}...` : value  }</li>)
                                return(<li>{value}</li>)
                                })}
                            </ul>
                        </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>

            {idea.validated ?  "" : 
            <div className="validate-footer">
            <button className="btn btn-primary btn-th" onClick={handleShow}>Validate this Product</button>
            </div>
            }

            <IdeaValidate show={show} setShow={handleClose} ValidateIdea={showPopup} disabledItems={disabledItems}/>
            <AlertPopup open={alertOpen} setOpen={setAlertOpen} message={message} variant={"error"}/>      
        <br /><br /><br />

        
        { validatePopup ?                           
                <div className={` ${validatePopup ? 'modal fade show' : ''}`} style={{ display: validatePopup ? 'block' : 'none', background: '#000000C9' }}>
                <div className="modal-dialog modal-dialog-centered">
                 <div className="modal-content rounded shadow border-0">
        
                     <div className="modal-body py-5">
                     { <Link to="/review2earn" style={{position:"absolute", top:"0",right:"0"}}  className="btn btn-icon btn-close btn-cus-close"><i className="uil uil-times fs-4 text-dark"></i></Link> }
                        <div className="text-center">
                            <div className="mt-0">
                                <>
                                <h4>Congrats  🎉 </h4>
                                 <p className="head-gr-modal mb-0" style={{fontSize:"22px"}}>You just crushed that product review and unlocked  <span style={{color:"#00eb00"}}>50 xp</span> reward!</p>
                                 {/* <p className="head-gr-modal mb-0" style={{fontSize:"22px"}}>Gonna be groovy 🥳</p> */}
                                 <br />
                                 <div className="mt-4">
                                <Link to="/review2earn" className="btn btn-warning" >Evaluate more</Link>
                                </div>
                                </>
                             </div>
                         </div>
                     </div>
                  </div>
              </div>
            </div>
             : "" } 
        </section>
        }
    </div>
 
  )
}

export default IdeaDetail


