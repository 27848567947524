import React from "react";
import { useNavigate } from "react-router-dom";

const IdeaCard = ({data}) => {

    const navigate = useNavigate()

  return (
    <div className="tiny-slide">
      <div className="col-lg-4 col-md-6 mt-4 pt-2">
        <div className="card pricing pricing-primary business-rate border-0 p-4 rounded-md shadow width-100">
          <div className="card-body p-0">
            <div style={{ display: "flex" }}>
              <h2 className="head-pr-id">{data.idea_name}</h2>
              <img
                src={data.idea_logo}
                alt="logo"
                style={{ height: "50px", marginLeft: "auto" }}
              />
            </div>
            <br />
            <p className="desc-para-id" >{data.one_line_desc.slice(0,55)}</p>

            <div className="content-id-pr border-top">
              <div className="mt-4">
                <p className="para-id-pr">
                  Product Type
                  <span className="ans">{data.product_type}</span>
                </p>
                <p className="para-id-pr">
                  Category
                  {data.category && data.category.length > 0 ? (
                    data.category.slice(0, 2).map((category, index) => {
                      return (
                        <span className="ans ">{category.value} &nbsp;</span>
                      );
                    })
                  ) : (
                    <span className="ans ">Not Available</span>
                  )}
                </p>
                <p className="para-id-pr ">
                  WhitePaper
                  {data.whitepaper_link === "" ? (
                    <span className="ans ">Not Available</span>
                  ) : (
                    <a
                      href={data.whitepaper_link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "aquamarine", float: "right" }}
                    >
                      View
                    </a>
                  )}
                </p>
              </div>
            </div>

            <div class="mt-4 d-flex justify-content-between">
              <div class="d-grid justify-content-between">
                <ul class="list-unstyled social-icon social mb-0">
                {data.twitter_link && 
                  <li class="list-inline-item mb-0">
                    <a href={data.twitter_link} target="_blank" class="rounded circle link-color" rel="noreferrer">
                      <i class="uil uil-twitter align-middle " title="Twitter"></i>
                    </a>
                  </li>
                } 
                &nbsp;
                  {data.linkedin_link && 
                  <li class="list-inline-item mb-0">
                    <a href={data.linkedin_link} target="_blank" class="rounded circle link-color" rel="noreferrer">
                      <i class="uil uil-telegram align-middle " title="Telegram"></i>
                    </a>
                  </li>
                  }
                  &nbsp;
                  {data.liveProduct_link && 
                  <li class="list-inline-item mb-0">
                    <a href={data.liveProduct_link} target="_blank" class="rounded circle link-color" rel="noreferrer">
                      <i class="uil uil-globe align-middle " title="Website"></i>
                    </a>
                  </li>
                  }
                  &nbsp;
                </ul>
              </div>
              <div class="d-grid">
                <button
                  onClick={() => {
                    navigate(`/review2earn/products/${data._id}`);
                  }}
                  className="btn btn-primary btn-th"
                >
                  Validate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdeaCard;
