
import { Routes, Route, Navigate } from "react-router-dom";
import { useState,useEffect } from "react";
import { apiURI } from "./config";
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

//CSS Imports

import "./assests/css/icons.min.css"
import "./assests/css/style-dark.min.css"
import "./assests/css/customize.css"
import "./assests/css/bootstrap-dark.min.css"



//Page Components
import HomePage from "./Pages/HomePage/HomePage";
import QuizHomePage from "./Pages/QuizPage/QuizHomePage";
import Profile from "./Pages/Profile/Profile";
import UnderConstruction from "./Pages/UnderConstruction/UnderConstruction";

import Review2Earn from "./Pages/Review2Earn/Review2Earn";
import Ideas from "./Pages/Review2Earn/Ideas/Ideas";
import TopRated from "./Pages/Review2Earn/TopRated";


import { useDispatch } from "react-redux";
import { wallet_address, profile_icon, user_data } from "./reducers/ConstantSlice";

import loadingIMG from "./assests/icons/loading.gif"

import { Buffer } from "buffer";  
import IdeaDetail from "./Pages/Review2Earn/Ideas/IdeaDetail";
import DeckDetails from "./Pages/Review2Earn/Decks/DeckDetails";
import IdeaJamDetail from "./Pages/Review2Earn/IdeaJam/IdeaJamDetail";
import Resources from "./Pages/Resources/Resources";
window.Buffer = window.Buffer || Buffer;

function App() {

  const [loading, setLoading] = useState(true);  
  const [walletConnected, setWalletConnected] = useState(false);
  const dispatch = useDispatch();

  const getGuardian = async (walletAddress) => {

    try{
        var query = `
        query Query($walletAddress: String) {
          getGuardian(walletAddress: $walletAddress) {
            _id
            wallet_address
            profile_picture
            guardian_level
            total_xp
            unclaimed_rewards
            claimed_rewards
            total_rewards
            ideas_reviewed
            projects_reviewed
            deals_reviewed
            milestone_reviewed
          }
        }
            `;
        await fetch(apiURI.URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                'x-power':process.env.REACT_APP_POWER_KEY,
                'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
                'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
                'x-range-name':process.env.REACT_APP_RANGE_NAME
            },
            body: JSON.stringify({
                query,
                variables: {
                      "walletAddress": walletAddress
                  }
                })
            })
            .then((response) => {
                const json = response.json();
                return json;
            })
            .then(async (data) => {
                if(data?.data?.getGuardian){
                  dispatch(user_data(data.data.getGuardian))
                }
            })
        } 
    catch (error) {
        console.log(error)
    }
  }

  const link = createUploadLink({
    uri: apiURI.URL
    });

  const cache = new InMemoryCache();

  const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                'x-power':process.env.REACT_APP_POWER_KEY,
                'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
                'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
                'x-range-name':process.env.REACT_APP_RANGE_NAME
            }
        };
    });

  const client = new ApolloClient({
        link: authLink.concat(link),
        cache,
        resolvers: {},
        connectToDevTools: true
    });


  //Wallet Configuration 
  useEffect(() =>{
    if(window.localStorage.getItem("walletAddress") !== null){
      const wallet = window.localStorage.getItem("walletAddress")
      const profileImg = window.localStorage.getItem("icon") 
      dispatch(wallet_address(wallet))
      dispatch(profile_icon(profileImg))
      getGuardian(wallet);
    }else{
      window.localStorage.setItem("walletAddress","disconnect");
      window.localStorage.setItem("icon","")
    }
  },[])


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);


  return (
    <div>
      {loading ? (
        <div style={{marginTop: "15%"}}>
          <img src={loadingIMG}
               className="avatar avatar-small rounded  d-block mx-auto mb-3 "alt="Loading" style={{ height: "300px", width: "auto" }} />
        </div>

      ) : (
        
      <ApolloProvider client={client}>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage walletConnected={walletConnected} setWalletConnected={setWalletConnected}/>} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/quiz" element={<QuizHomePage />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/review2earn" element={<Review2Earn />}>
                {/* <Route index element={<TopRated />} />
                <Route path="TopRated" element={<TopRated />} />
                <Route path="ideas" element={<Ideas />} />
                <Route path="*" element={<Navigate to="/review2earn/TopRated" />} /> */}
            </Route>
            <Route path="/review2earn/products/:id" element={<IdeaDetail />} /> 
            {/* Add dynamic id param */}
            {/* <Route path="/review2earn/decks/:id" element={<DeckDetails />} />  */}
            {/* Add dynamic id param */}
            {/* <Route path="/review2earn/idea-jams/:id" element={<IdeaJamDetail />} />  */}
            {/* Add dynamic id param */}
            <Route path="/under-construction" element={<UnderConstruction />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </ApolloProvider>
      )}
    </div>
    
  );
}

export default App;





