import React,{useState,useEffect} from 'react'

import { apiURI } from '../../config';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Blogs = () => {

  
  const [articles, setArticles] = useState([]);

  const getArticles = async () => {
    try{
        var query = `
        query GetArticles($id: ID) {
          getArticles(_id: $id) {
            title
            author
            image_url
            content
            url
          }
        }
            `;
        await fetch(apiURI.URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                'x-power':process.env.REACT_APP_POWER_KEY,
                'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
                'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
                'x-range-name':process.env.REACT_APP_RANGE_NAME

            },
            body: JSON.stringify({
                query,
                variables: {
                        id: "test"
                  }
                })
            })
            .then((response) => {
                const json = response.json();
                return json;
            })
            .then(async (data) => {
                // console.log(data.data.getArticles)
                if(data?.data?.getArticles){
                  setArticles(data.data.getArticles)
                }
            })
        
        } 
    catch (error) {
        console.log(error)
    }
  }

  const mediumBlogs = [
  {
    title:
      "Venture Capitalists are Pouring Money into Web3. Here's Why — CR Square",
    description:
      "Firms like M31 Captial, Panterra & Animoca Brands recently raised hundreds of millions of dollars focused on Web3 investment.",
    time: "1",
    image:
      " https://miro.medium.com/v2/resize:fit:720/format:webp/1*XQUKGW9L9J9eExn7vLTbLg.png",
    url: " https://medium.com/@crsquare.finance/venture-capitalists-are-pouring-money-into-web3-heres-why-cr-square-d8a73ef0aae7",
  },

  {
    title:
      "5 Lean Startup Tips Every Founder Should Know Incase Funding Dries Up",
    description:
      "While some project founders shudder at the idea of scaling their business on a budget, it can be done effectively even with limited resources. By understanding a startup's growth stages and knowing what expenses to cut, a company can continue to grow without breaking the bank. Scaling on a budget is possible with a bit of planning and effort.",
    time: "4",
    image:
      " https://miro.medium.com/v2/resize:fit:720/format:webp/1*yfNbiB-ty_0CGPZdPUhUXg.png",
    url: " https://medium.com/@crsquare.finance/5-lean-startup-tips-every-founder-should-know-incase-funding-dries-up-948b921544e0",
  },

  {
    title:
      "What is a Proof of Reserves Audit and How Will It Reshape The Entire Crypto Sector? — CR Square",
    description:
      "Widespread adoption of PoR by exchanges will enhance your asset safety, but how exactly does it do this?",
    time: "4",
    image: " https://miro.medium.com/v2/resize:fit:720/0*hSmVgdqIuNmIbAeW",
    url: " https://medium.com/@crsquare.finance/what-is-a-proof-of-reserves-audit-and-how-will-it-reshape-the-entire-crypto-sector-c-dca8fd45727b",
  },

  {
    title:
      "Crypto Exchanges Scramble To Publish 'Proof-of-Reserves' Following FTX's Implosion - CR Square",
    description:
      "Kucoin, OKX & many more reveal plans to publish their reserves & Gate, Kraken & Bitmex already have. But what is proof of reserves?",
    time: "4",
    image:
      " https://miro.medium.com/v2/resize:fit:720/format:webp/1*iIA6Ru5vCHJedFbZHtIj6Q.png",
    url: " https://medium.com/@crsquare.finance/crypto-exchanges-scramble-to-publish-proof-of-reserves-following-ftx-s-implosion-cr-square-6e48a00b65",
  },

  {
    title: "How to Prepare for a Successful Fundraising Round — CR Square",
    description: " ",
    time: "5",
    image:
      " https://miro.medium.com/v2/resize:fit:720/format:webp/1*iIB2EtgnQJo97b7lUzVa0A.png",
    url: " https://medium.com/@crsquare.finance/how-to-prepare-for-a-successful-fundraising-round-cr-square-1ec81941c661",
  },

  {
    title: "The 5 Best Angel Investment Tools/Platforms For Startups",
    description: "",
    time: "4",
    image:
      " https://miro.medium.com/v2/resize:fit:720/format:webp/1*5kbL8t8HP-KdwMMxqXbZhw.png",
    url: " https://medium.com/@crsquare.finance/the-5-best-angel-investment-tools-platforms-for-startups-c314bebd9edc",
  },
];

  var settings = {
    dots: false,
    infinite: true,
    swipeToSlide: true,
    initialSlide: 0,
    slidesToShow: 4,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "ease-out",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows:false,
          initialSlide: 0,
          infinite: true,
        },
      },
    ],
  };

  useEffect(() => {
    getArticles();
  }, [])


  return (
    <div>
        <Slider {...settings}> 
        {mediumBlogs.map((value,index) => {
            return (
                    <a href={value.url} target="_blank" rel="noreferrer">
                    <div style={{paddingRight:"10px"}}>
                        <div className="card blog blog-primary border-0 shadow rounded overflow-hidden" >
                            <div className="position-relative overflow-hidden" style={{display:"contents"}}>
                                <img src={value.image} className="news-image" alt="Image"  />
                            </div>
                            <div className="card-body content blog-text">
                                <p className="text-dark  h6 news-title">{value.title.length > 50 ? value.title.substring(0, 50) + '...' : value.title}</p>
                                <div className="mt-2">
                                <a className="blog-text">{value.description.length > 30 ? value.description.substring(0, 30) + '...' : value.author}</a>
                                <br /><br />
                                    <a href="shop-blog-detail.html" className="blog-text">Read more ... </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </a>
                )})}
        </Slider> 
        <br />
        <br />
        <Slider {...settings}> 
        {articles.map((value,index) => {
            return (
                    <a href={value.url} target="_blank" rel="noreferrer">
                    <div style={{paddingRight:"10px"}} onClick={() => {}}>
                        <div className="card blog blog-primary border-0 shadow rounded overflow-hidden" >
                            <div className="position-relative overflow-hidden" style={{display:"contents"}}>
                                <img src={value.image_url} className="news-image" alt="Image"  />
                            </div>
                            <div className="card-body content blog-text">
                                <p className="text-dark  h6 news-title">{value.title.length > 50 ? value.title.substring(0, 50) + '...' : value.title}</p>
                                <div className="mt-2">
                                <a className="blog-text">{value.author.length > 15 ? value.author.substring(0, 50) + '...' : value.author}</a>
                                <br /><br />
                                    <a href="shop-blog-detail.html" className="blog-text">Read more ... </a>
                                </div>
                            </div>

                        </div>
                    </div>
                    </a>
                )})}
        </Slider> 
        <br />

    </div>
  )
}

export default Blogs;