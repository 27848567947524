import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import {apiURI} from "../../config";
import { useSelector, useDispatch } from "react-redux";
import { user_data } from "../../reducers/ConstantSlice";

// Page Components
import Header from "../../Components/Header";

// Other Components
import { useSDK } from "@thirdweb-dev/react";
import Spinner from 'react-bootstrap/Spinner';

const Quiz = ({quizStarted,setQuizStarted, questions}) => {

    // Current Question to Mange state of Viewing Question 
    // Initialize Question && questionIndexData to Create an array of 10 with managing the State of Questions
    // answers store answers of all the questions
    // setScore to store result from the Backend Api 
    // quizStatus to Toggle Test Complete Pop-up

// *********************************************************************VARIABLES****************************************************************************** 

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const initializeQuestions = Array(10).fill().map(() => `Unattempted`);
  const [questionIndexData, setQuestionIndexData] = useState(initializeQuestions);
  const [answers, setAnswers] = useState([]);
  const [timer, setTimer] = useState(300);
  const [quizStatus, setQuizStatus] = useState(true);
  const [mintPopup, setMintPopup] = useState(false);
  const [txnHash,setTxnHash]= useState("");
  const [loading,setLoading] = useState(true);
  
  const [score,setScore] = useState(0);
  
  //Redux Variables
  const dispatch=useDispatch();
  const walletAddress = useSelector((state) => state.constVar.wallet_address)
  const sdk = useSDK();

  //Timer Format options
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60 >= 10 ? timer % 60 : `0${timer % 60}`;
  //Submit Button Enable/Disable Variable
  const allQuestionsAnswered = questionIndexData.every((answer) => answer !== "Unattempted");


//Sample Data Format
//   const questions1 = [
//     {
//         index: "1",
//         question: "What is the capital of India?",
//         options: ["New Delhi", "Mumbai", "Bangalore", "Chennai"],
//         answer: "New Delhi",
//     },
//   ];

// Reduces the timing in seconds till it reaches the limit
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer !== 0) {
          return prevTimer - 1;
        } else {
          return prevTimer;
        }
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // perform action when timer hits 0
  useEffect(() => {
    if (timer === 0) {
      // alert("Your Time has Expired")
      handleSubmit();
    }
  }, [timer]);



 // *********************************************************************FUNCTIONS****************************************************************************** 
 
  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrev = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  // Set QuestionIndexData to Marked 
  const handleMark = () => {
    const newData = [...questionIndexData]; 
    newData[currentQuestion] = "Marked"; 
    setQuestionIndexData(newData);
    currentQuestion < 9 ? setCurrentQuestion(currentQuestion + 1) : setCurrentQuestion(0);
  };

  // Reset Question options and reset marked 
  const handleClear = () =>{
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = undefined;
    setAnswers(newAnswers);
    const newData = [...questionIndexData]; 
    newData[currentQuestion] = "Unattempted"; 
    setQuestionIndexData(newData);
  }

  //set the option to answer array and  QuestionIndexData to Answered
  const handleOptionClick = (option) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = option;
    setAnswers(newAnswers);
    const newData = [...questionIndexData]; 
    newData[currentQuestion] = "Answered"; 
    setQuestionIndexData(newData);
  };

  // Navigating through the Question Numbers
  const handleQuestionChange = (index) =>{
    setCurrentQuestion(index)
  }

  const handleSubmit = async () => {
    setTimer(0);
    setQuizStatus(false)
    const questionIndex = questions.map(value => value.index);
    const answerIndex = answers.map(value => value + 1);
    try {
      await getScore(questionIndex, answerIndex);
    } catch (error) {
      console.log("Error:", error);
    }

    //Setting CSS To Make Screen Fixed
    const body = document.querySelector('body');
    body.style.overflow = 'hidden';
  };
  
  const handleMint = async (result) => {
    const body = document.querySelector('body');
    body.style.overflow = '';
    if(result === "Pass"){
      setQuizStatus(true)
      setMintPopup(true)
      await createUser();
    }else{
      setQuizStarted(false)
    }
  }

  const signMessage = async () => {
    
    // This is the message to be signed
    const message = "Claim Your Pawsifer";
    // Now we can sign the message with the connected wallet
    try{
      const signature = await sdk.wallet.sign(message);
      console.log("Signature ",signature);
      handleMint("Pass");
    }
    catch (e){
      alert("Please Accept the Signature Request to Claim your NFT");
    }

}


  /// API CAllS
  const getScore = async (questionIndex,answerIndex) => {
    try{
        var query = `
        query Query($input: Answer) {
            getScore(input: $input)
          }
            `;
        fetch(apiURI.URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                'x-power':process.env.REACT_APP_POWER_KEY,
                'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
                'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
                'x-range-name':process.env.REACT_APP_RANGE_NAME

            },
            body: JSON.stringify({
                query,
                variables: {
                    "input": {
                      "questionIndex": questionIndex,
                      "answerIndex": answerIndex
                    }
                  }
                })
            })
            .then((response) => {
                const json = response.json();
                return json;
            })
            .then(async (data) => {
                if(data?.data?.getScore){
                  setScore(data.data.getScore)
                }
                return 0;
            })
        
        } 
    catch (error) {
        console.log(error)
    }
  }
  const createUser = async () => {
    try{
        var query = `
         mutation Mutation($input: GuardianInput) {
          createGuardian(input: $input) {
            _id
            wallet_address
            guardian_level
            total_xp
            unclaimed_rewards
            claimed_rewards
            total_rewards
            ideas_reviewed
            projects_reviewed
            deals_reviewed
            milestone_reviewed
            txn_hash
          }
        }
             `;
       fetch(apiURI.URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                'x-power':process.env.REACT_APP_POWER_KEY,
                'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
                'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
                'x-range-name':process.env.REACT_APP_RANGE_NAME

            },
            body: JSON.stringify({
                query,
                variables: {
                    "input": {
                      wallet_address: walletAddress,
                      guardian_level : 1,
                      total_xp: 100,
                      unclaimed_rewards : 0,
                      claimed_rewards : 0,
                      total_rewards : 100,
                      ideas_reviewed : 0,
                      projects_reviewed : 0,
                      deals_reviewed : 0,
                      milestone_reviewed : 0,
                      xp_level: 1,
                    }
                  }
                })
            })
            .then((response) => {
                const json = response.json();
                return json;
            })
            .then(async (data) => {
                if(data?.data?.createGuardian){
                  dispatch(user_data(data.data.createGuardian))
                  setTxnHash(data.data.createGuardian.txn_hash)
                  setLoading(false)
                }
            })
        } 
    catch (error) {
        console.log(error)
        alert(error)
    }
  }

  // *********************************************************************DESIGN****************************************************************************** 
 
return (
    <>
    { !quizStarted ? "" :  
    
    <div>
    <Header />
     <div className="container mt-4" >
        <div className="row align-items-center">
          <div className="col-12 mb-4">
            <div className="sidebar-questions rounded shadow d-flex justify-content-between">
                <div className="p-4">
                    <span style={{paddingRight:"0.3px"}}/> 
                        {questionIndexData.map((value, index) => (
                          <>
                            <button onClick={() => handleQuestionChange(index)} className = {value === "Unattempted" ? "btn btn-outline-primary" : value === "Marked" ? "btn btn-warning": "btn btn-success"}> {index + 1} </button>  <span style={{marginRight:"0.3px"}}/> 
                          </>))}
                </div>
                <div className="time p-4 float-left">
                    <h3 style={{fontWeight: "600"}}>Time Left : {minutes}:{seconds} </h3>
                </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 order-2 order-md-0 mt-5 mt-sm-0 mb-5">
            <div className="section-title" style={{height: "300px"}}>
              <h1  className="title mb-3" style={{fontWeight: "600"}}>{currentQuestion + 1}.{questions[currentQuestion].question}</h1>

               {questions[currentQuestion].options.map((option, index) => (
                  <div className="custom-control custom-radio custom-control-inline" >
                    <div className="form-check mb-2">
                      <input  style={{border : "1px solid #2a3c51",backgroundColor: "#1c2836"}} className="form-check-input" 
                          checked={answers[currentQuestion] === index }  type="radio" value={option} 
                          name={option}  id={option}  onChange={() => handleOptionClick(index)}/>
                      
                      <label className="form-check-label rd-c" for={option}>{option}</label>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-12 mb-0">
            <div className="component-wrapper rounded shadow justify-content-between">
              <div className="p-4" >
                <button disabled={currentQuestion === 0 ? true : false } onClick={() => handlePrev()} className="btn btn-primary button-responsive"> Previous </button>
                <button onClick={() => handleMark()} className="btn btn-primary button-responsive"> Mark for Review & Next </button>
                <button onClick={() => handleClear()} className="btn btn-primary button-responsive"> Clear Response </button>
                <button disabled={currentQuestion === 9 ? true : false } onClick={() => handleNext()} className="btn btn-primary button-responsive"> Next </button>
                <button style ={{display:allQuestionsAnswered ? "" : "none" , float:"right"}} onClick={() => handleSubmit()}  className="btn btn-primary button-responsive btn-th">Submit</button>
              </div>
            </div>
          </div>
          </div>
    </div>

        <footer className="footer  ft-re">    
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-py-30 footer-border">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="align-items-center justify-content-center text-center">
                                            <p className="value-mark">{questionIndexData.filter((str) => str === "Answered").length}</p>
                                            <h6 className="mb-0"style={{fontWeight: "600"}}> Answered</h6>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="align-items-center justify-content-center text-center">
                                            <p className="value-mark">{questionIndexData.filter((str) => str === "Unattempted").length}</p>
                                            <h6 className="mb-0" style={{fontWeight: "600"}}>Unattempted</h6>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="align-items-center justify-content-center text-center">
                                            <p className="value-mark">{questionIndexData.filter((str) => str === "Marked").length}</p>
                                            <h6 className="mb-0" style={{fontWeight: "600"}}>Marked</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </footer>

    { quizStatus ? "" :                          
        <div className={` ${!quizStatus ? 'modal fade show' : ''}`} style={{ display: !quizStatus ? 'block' : 'none', background: '#000000C9' }}>
          <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded shadow border-0">

             <div className="modal-body py-5">
                    <div className="text-center">
                      <div className="mt-0">
                         <h4>Score: <span>{score * 10 }/100</span> </h4>
                          {score >= 5 ? 
                          <>
                            <p className="head-gr-modal mb-0"> Whoa, You are a Pawsifer now! 🥳</p>
                            <p className="text-muted">Congrats on crushing that test like a boss</p>
                            <div className="mt-4">
                             <button onClick={() => signMessage()} className="btn btn-primary btn-th">Claim</button>
                            </div>
                            </>  
                          :
                          <>
                          <p className="head-gr-modal mb-0">You missed the funky beat 🥺</p>
                          <p className="text-muted">Try to hit the bull's eye again</p>
                          <div className="mt-4">
                             <button onClick={() => handleMint("Fail")} className="btn btn-primary btn-th">Retry</button>
                          </div>
                          </> 
                         }
                     </div>
                 </div>
             </div>
          </div>
      </div>
    </div> }

    { mintPopup ?                           
        <div className={` ${mintPopup ? 'modal fade show' : ''}`} style={{ display: mintPopup ? 'block' : 'none', background: '#000000C9' }}>
        <div className="modal-dialog modal-dialog-centered">
         <div className="modal-content rounded shadow border-0">

             <div className="modal-body py-5">
             {!loading ? <Link to="/profile" style={{position:"absolute", top:"0",right:"0"}}  className="btn btn-icon btn-close btn-cus-close"><i className="uil uil-times fs-4 text-dark"></i></Link>  : ""}
                <div className="text-center">
                    <div className="mt-0">
                         
                        
                        {loading ? 
                        <>
                         <h4>Hooray, You've earned <span style={{color:"#00eb00"}}> 100 xp! </span> </h4>
                         <p className="head-gr-modal mb-0" style={{fontSize:"22px"}}>NFT minting! Pawsifer is coming your way</p>
                         <p className="head-gr-modal mb-0" style={{fontSize:"22px"}}>Gonna be groovy 🥳</p>
                         <br />
                         <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        </>
                        : <>
                        <p className="head-gr-modal mb-0"> Wanna check out your cool new NFT ? </p>
                        <p className="head-gr-modal mb-0" ><a href={`https://polygonscan.com/tx/${txnHash}`} target="_blank">Click here</a> to view your transaction</p>
                          <div className="mt-4">
                            <a href="https://opensea.io/assets/matic/0xe26881573e89bccc74b2e330fd0e44fe49eb4a82/0" target="_blank" className="btn btn-primary btn-th" >View NFT 🥳</a>
                          </div>
                        </>}
                     </div>
                 </div>
             </div>
          </div>
      </div>
    </div>
     : "" } 


    </div>
  }
  </>
  )};

export default Quiz;
