import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

import { setContext } from "apollo-link-context";

const cache = new InMemoryCache();

export const apiURI = {
  URL: 'https://api.crsquare.finance/graphql/',
};


const httpLink = createHttpLink({
  uri: apiURI.URL,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

export const client = new ApolloClient({
  uri: authLink.concat(httpLink),
  // "https://dev.crsquare.finance/graphql/",
  cache: cache,
});


