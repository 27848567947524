import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import crsquare from "../assests/icons/CRSquare.png"
import { useNavigate } from "react-router-dom";

const TinySlider = () => {

  const navigate = useNavigate();


  var settings = {
    // dots: true,
    arrows:false,
    infinite: false,
    speed: 500,
    // rows: 2,
    // slidesPerRow: 2,
    // slidesToScroll: 1,
    slidesToShow: 3,
    swipeToSlide: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          infinite: false,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows:false,
          infinite: false,
          // dots:true
        },
      },
    ],
  };

  const items = [
    {
      idea_name: "Startup",
      idea_one_line: "One Line Desc 1",
      idea_logo: "idea logo 1",
      idea_category: "B2B 1",
      idea_product_type: "idea product type 1",
      whitepaper: "View"
    },
    {
      idea_name: "Startup",
      idea_one_line: "One Line Desc 2",
      idea_logo: "idea logo 2",
      idea_category: "B2B 2",
      idea_product_type: "idea product type 2",
      whitepaper: "N.A"
    },
    {
      idea_name: "Startup ",
      idea_one_line: "One Line Desc 3",
      idea_logo: "idea logo 3",
      idea_category: "B2B 3",
      idea_product_type: "idea product type 3",
      whitepaper: "View"
    },
    // {
    //   idea_name: "Startup ",
    //   idea_one_line: "One Line Desc 4",
    //   idea_logo: "idea logo 4",
    //   idea_category: "B2B 4",
    //   idea_product_type: "idea product type 4",
    //   whitepaper: "N.A"
    // },
    // {
    //   idea_name: "Startup ",
    //   idea_one_line: "One Line Desc 5",
    //   idea_logo: "idea logo 5",
    //   idea_category: "B2B 5",
    //   idea_product_type: "idea product type 5",
    //   whitepaper: "View"
    // },
    // {
    //   idea_name: "Startup ",
    //   idea_one_line: "One Line Desc 6",
    //   idea_logo: "idea logo 6",
    //   idea_category: "B2B 6",
    //   idea_product_type: "idea product type 6",
    //   whitepaper: "N.A"
    // },
  ];

  return (
    <div>
      <Slider {...settings}>
        {items.map((value, index) => {
          return (
            <div className="tiny-slide blur">
              <div className="col-lg-4 col-md-6 mt-4 pt-2">
                <div className="card pricing pricing-primary business-rate border-0 p-4 rounded-md shadow width-100">
                  <div className="card-body p-0">
                    <div style={{display:"flex"}} >
                    <h2 className="head-pr-id">{value.idea_name}</h2>
                    <img src={crsquare} alt=""  style={{height:"50px",marginLeft:"auto"}} />
                    </div>
                    <p className="desc-para-id">{value.idea_one_line}</p>

                    {/* <div className="tags mb-4">
                      <span className="py-2 px-4 d-inline-block bg-soft-primary h6 mb-0 text-primary"> */}
                        {/* {value.idea_category} */}
                        {/* CATEGORY
                      </span>

                    </div> */}
                    
                    <div className="content-id-pr border-top">
                      <div className="mt-4">

                      <p className="para-id-pr">
                        Product Type
                        <span className="ans">B2B</span>
                      </p>
                      <p className="para-id-pr">
                        Category
                        <span className="ans ">Blockchain </span> 
                        <span className="ans ">Market   &nbsp;</span>
                      </p>
                      <p className="para-id-pr ">
                        WhitePaper
                        <span className="ans ">{value.whitepaper}</span> 
                      </p>
                      </div>
                    </div>

                    {/* <div className="mt-4  justify-content-between ">
                      <div className="d-grid">
                        <button onClick={()=> {navigate("/review2earn/Products/123")}}   className="btn btn-primary btn-th">Validate</button>
                      </div>
                    </div> */}
                    
                    <div class="mt-4 d-flex justify-content-between">
                      <div class="d-grid justify-content-between">
                          <ul class="list-unstyled social-icon social mb-0">
                            {/* <li class="list-inline-item mb-0"><a  class="rounded circle link-color"><i class="uil uil-twitter align-middle " title="twitter"></i></a></li>&nbsp;
                            <li class="list-inline-item mb-0"><a class="rounded circle"><i class="uil uil-telegram align-middle" title="telegram"></i></a></li>&nbsp;
                            <li class="list-inline-item mb-0"><a  class="rounded circle"><i class="uil uil-linkedin align-middle" title="linkedin"></i></a></li> */}
                          </ul>
                      </div>
                      <div class="d-grid">
                      <button   className="btn btn-primary btn-th">Validate</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default TinySlider;
