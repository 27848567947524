import React from "react";

//Icons 
import Discord from "../assests/social_icons/discord.png";
import FB from "../assests/social_icons/fb.png";
import LinkedIn from "../assests/social_icons/in.png";
import Telegram from "../assests/social_icons/tele.png";
import Twitter from "../assests/social_icons/twitter.png";
import Youtube from "../assests/social_icons/youtube.png";

//CSS 
import "./Footer.css";

const Footer = () => {
  return (

      <footer class="footer">    
            <div class="footer-py-20 footer-bar">
                <div class="container text-center pt-3 pb-3">
                    <div class="row align-items-center">
                        <div class="col-sm-12">
                            <div class="text-center" style={{display:"flex",justifyContent: "space-between"}}>
                                <p class="cpy" style={{marginLeft:"0px"}}>© Guardian-CF2 Labs 2023.</p>
                                <div style={{float:"right" ,marginTop: "3px"}}>
                                {/* Telegram */}<a href="https://t.me/crsquarefinance" target="_blank"><img src={Telegram} alt="Telegram_Icon" className="footer-icon mr-5"/></a>
                                {/* FaceBook */}<a href="https://www.facebook.com/people/CR-Square-Finance/100077961367014/" target="_blank"><img src={FB} alt="FB_Icon" className="footer-icon mr-5"/></a>
                                {/* Twitter */}<a href="https://twitter.com/Cr2_Finance" target="_blank"><img src={Twitter} alt="Twitter_Icon" className="footer-icon mr-5"/></a>
                                {/* Linkedin */}<a href="https://www.linkedin.com/company/cr-square-finance/" target="_blank"><img src={LinkedIn} alt="Instagram_Icon" className="footer-icon mr-5"/></a>
                                {/* Youtube */}<a href="https://www.youtube.com/channel/UCfBiwNGfsIr8U9KBB02b60Q" target="_blank"><img src={Youtube} alt="Youtube_Icon" className="footer-icon mr-5"/></a>
                                {/* Discord */}<a href="https://discord.com/invite/2g4RmFMDrA" target="_blank"><img src={Discord} alt="Discord_Icon" className="footer-icon mr-5"/></a>
                                {/* Telegram */}<a href="https://t.me/crsquare_finance" target="_blank"><img src={Telegram} alt="Telegram_Icon" className="footer-icon mr-5"/></a>

                                </div>
                            </div>
                        </div>
                      </div>
                </div>
            </div>
        </footer>

  );
};

export default Footer;
