import React, { useState, useRef, useEffect } from "react";
import "./BulletInput.css"

const BulletPointsInput = ({parentState, setParentState}) => {
  const [bulletPoints, setBulletPoints] = useState(parentState || []);
  const lastInputRef = useRef(null);

  const handleAddBulletPoint = (index) => {
    setBulletPoints([...bulletPoints, ""]);
    if(lastInputRef.current) {
      lastInputRef.current.focus();
    }
  };
  

  const handleBulletPointChange = (event, index) => {
    const newBulletPoints = [...bulletPoints];
    newBulletPoints[index] = event.target.value;
    setBulletPoints(newBulletPoints);
    setParentState(newBulletPoints);
  };

  const handleRemoveBulletPoint = (index) => {
    const newBulletPoints = [...bulletPoints];
    newBulletPoints.splice(index, 1);
    setBulletPoints(newBulletPoints);
    setParentState(newBulletPoints);
  };


  
  return (
    <div>
    {bulletPoints.map((bulletPoint, index) => (
         <div key={index} className="bullet-point-container"> 
          <input
            className="bullet-point-input"
            type="text"
            ref={lastInputRef}
            value={bulletPoint}
            onChange={(event) => handleBulletPointChange(event, index)}
          />          
          <button className="remove-button" style={{display:bulletPoints.length <= 2 ? "none" : "flex",justifyContent: "space-around"}} onClick={() => handleRemoveBulletPoint(index)}>-</button>
          <button className="add-button" style={{display: bulletPoints.length >= 5 ? "none" : "flex",justifyContent: "space-around"}} onClick={() => handleAddBulletPoint()}>+</button>

        </div> 
    ))}
    </div>
  );
};

export default BulletPointsInput;
