import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { apiURI } from "../../../config";

import All_IdeaJam from "./All_IdeaJam";
import { useNavigate } from "react-router-dom";

const IdeaJam =  () => {
  
  const navigate = useNavigate();

  const [featuredIdeas, setFeaturedIdeas] = useState([]);
  const [recentIdeas, setRecentIdeas] = useState([]);
  const [allIdeas, setAllIdeas] = useState([]);


  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    // slidesToScroll: 1,

    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          // infinite: true,
          // dots:true
        },
      },
    ],
  };



  const getIdeasData = async () => {
      try {
        var query = `
        query AllIdeasGuardian($id: ID) {
          allIdeasGuardian(_id: $id) {
            idea_name
            idea_logo
            one_line_desc
            category {
              value
            }
            product_type
            whitepaper_link
            twitter_link
            linkedin_link
            liveProduct_link
            _id
          }
        }
              `;
        await fetch(apiURI.URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-power':process.env.REACT_APP_POWER_KEY,
            'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
            'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
            'x-range-name':process.env.REACT_APP_RANGE_NAME
          },
          body: JSON.stringify({
            query,
            variables: {
              id : "test"
            }
          }),
        })
          .then((response) => {
            const json = response.json();
            return json;
          })
          .then(async (data) => {
              // console.log("Idea 1234" ,data.data.recentIdeas,data.data.allIdeasGuardian);
              if (data?.data?.allIdeasGuardian) {
                setAllIdeas(data.data.allIdeasGuardian)
              }
          });
      } catch (error) {
        console.log(error);
      }
  }

  
  useEffect(() => {  
    const fetchData = async () => {
      // await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 10 seconds before making the API call
      await getIdeasData(); // Make the API call
    }
    fetchData();
  }, [])
  

  return (
    <div>
      {/* All Ideas */}
                <All_IdeaJam allIdeas={allIdeas}/>

              </div>

  );
};

export default IdeaJam;
