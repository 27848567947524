import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate, useOutlet, useLocation } from 'react-router-dom';
import TopRated from "./TopRated";
import Ideas from "./Ideas/Ideas";
import Footer from "../../Components/Footer"
import Decks from "./Decks/Decks";
import IdeaJam from "./IdeaJam/IdeaJam"
import { useDispatch, useSelector } from "react-redux";
import {activeReviewTab} from "../../reducers/ConstantSlice";

import information from "./information.png"

const Review2Earn = () => {

  const location = useLocation();
  // const outlet = useOutlet();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultActiveKey = useSelector((state) => state.constVar.activeReviewTab)
  const [activeTab, setActiveTab] = useState(defaultActiveKey || 'TopRated');


  useEffect(() => {

  }, [location])
  
console.log("Active Tab",activeTab)


  return (
    <div>
      <Header />
      <div class="container mt-4">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-left mb-4 pb-2">
              <h4 class="title mb-4">Review2Earn</h4>
              {activeTab === "ideaJam" && 
              <div className="d-flex">
              <img src={information} alt="disclaimer" className="warning_image" /> 
              <p style={{color:"red"}} className="warning_text"> &nbsp; To earn WCR2 tokens and XP, please clear the Level 1 Guardian test and become a PAWSIFER. If not, simply show your support for the idea you think is awesome by rating and sharing it on social media to gather more support.</p>
              </div>
              }
              <div class="row">
                <div class="col-lg-12">
                  <Tabs
                   defaultActiveKey={activeTab}
                  activeKey={activeTab}
                   fill
                      onSelect={(k) => {dispatch(activeReviewTab());setActiveTab(k)}}
                    className="mb-3 tab-color">

                    <Tab eventKey="TopRated" title="Top Rated 🔥" >
                    <TopRated setActiveTab={setActiveTab}/>
                    </Tab>
                    <Tab eventKey="ideas" title="Products 💡" >
                    <Ideas />
                    </Tab>
                    {/* <Tab eventKey="decks" title="Decks 📃" >
                    <Decks />
                    </Tab>
                    {/* <Tab eventKey="ideaJam" title="Idea Jam 💡" id="idea_jam" > 
                    <IdeaJam />
                    </Tab> */}
                    <Tab eventKey="projects" title="🔒 Projects " disabled >
                      Projects
                    </Tab>
                    <Tab eventKey="deals" title="🔒 Deals " disabled> 
                      Deals
                    </Tab>
                    <Tab eventKey="milestones" title="🔒 Milestones " disabled>
                      Milestones
                    </Tab>

                  </Tabs>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Review2Earn;
