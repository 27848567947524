import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Pagination } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import DeckCard from "./DeckCard";


const AllDecks = ({ allIdeas }) => {
  const navigate = useNavigate();
  const [sortMode, setSortMode] = useState("none"); // add sortMode state variable
  const [isDescending, setIsDescending] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  let sortedIdeas =
    sortMode === "name"
      ? [...allIdeas].sort((a, b) =>
          a.idea_name.localeCompare(b.idea_name, undefined, {
            sensitivity: "base",
          })
        )
      : sortMode === "type"
      ? [...allIdeas].sort((a, b) =>
          a.product_type.localeCompare(b.product_type, undefined, {
            sensitivity: "base",
          })
        )
      : allIdeas;

  sortedIdeas = isDescending ? sortedIdeas.reverse() : sortedIdeas;

  let filteredIdeas = sortedIdeas.filter((idea) =>
    idea.idea_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  var settings = {
    speed: 500,
    rows: filteredIdeas.length < 6 ? 1 : 2,
    slidesToShow: filteredIdeas.length <= 3 ? filteredIdeas.length : 3,
    swipeToSlide: true,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:  filteredIdeas.length <= 2 ? filteredIdeas.length : 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow:  filteredIdeas.length <= 2 ? filteredIdeas.length : 2,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // customPaging: function(i) {
          //   return (
          //       <p>1</p>
          //   );
          // },
          slidesToShow: 1,
          dots:true,
          dotsClass: "slick-dots slick-thumb",
          arrows: true,
          infinite: true,
        },
      },
    ],
  };



  return (
    <div>
      {/* <h4>Header {filteredIdeas.length}</h4> */}
      <div class="row pt-0">
        <div class="col-12">
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-cloud"
              role="tabpanel"
              aria-labelledby="pills-cloud-tab"
            >
              <div class="row pb-4 border-bottom">
                <div
                  className="mb-0 mt-4 all-ideas-text"                >
                  <h6 style={{ width: "150px" }}>All Decks &nbsp;</h6>
                  <div className="all-ideas-text">
                    <input
                      className="input-search "
                      type="text"
                      placeholder="🔎 Search by Product name"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <h6 style={{ display: "flex", alignItems: "baseline" }}>
                    <button
                      className={`btn btn-link all-ideas-text-btn ${
                        sortMode === "name" ? "active" : ""
                      } `}
                      onClick={() => {
                        if (sortMode === "name") {
                          setIsDescending(!isDescending);
                        } else {
                          setIsDescending(false);
                          setSortMode("name");
                        }
                      }}
                      data-sort="type"
                    >
                      Sort by Name
                      {sortMode === "name" &&
                        (isDescending ? <span>▲</span> : <span>▼</span>)}
                    </button>
                    <span className="all-ideas-text-btn">|</span>
                    <button
                      className={`btn btn-link all-ideas-text-btn  ${
                        sortMode === "type" ? "active" : ""
                      } `}
                      onClick={() => {
                        if (sortMode === "type") {
                          setIsDescending(!isDescending);
                        } else {
                          setIsDescending(false);
                          setSortMode("type");
                        }
                      }}
                    >
                      Sort by Type
                      {sortMode === "type" &&
                        (isDescending ? <span>▲</span> : <span>▼</span>)}
                    </button>
                  </h6>
                </div>

                {filteredIdeas.length === 0 ?
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <h6  className="mt-5" style={{textAlign:"center"}}>No Product's Found Matching your Text</h6>
                  <br />
                  <br />


                </div>
                : 
                <Slider {...settings}>
                  {filteredIdeas.map((value, index) => {
                    return <DeckCard data={value} />;
                  })}
                </Slider>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllDecks;
