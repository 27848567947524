import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import Header from '../../../Components/Header';
import {apiURI} from "../../../config";

import { useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

import "../../../../src/assests/css/customize.css"
import Rating from '@mui/material/Rating';

import { useSDK } from "@thirdweb-dev/react";
import { useSelector } from 'react-redux';


const IdeaValidate = ({show, setShow,ValidateIdea}) => {
    const { id } = useParams();
    const sdk = useSDK();
    // Validate Module
    const walletAddress = useSelector((state) => state.constVar.wallet_address)
    const storedAnswers = localStorage.getItem(`ideaJam-${id}`);
    const [comment, setComment] = useState(storedAnswers ? JSON.parse(storedAnswers)[0] : "");
    const [rating, setRating] = useState(storedAnswers ? JSON.parse(storedAnswers)[1] : 0)


  const handleClose = async(e) => {
    if(e === "Close"){
      const data = [comment,rating]
      window.localStorage.setItem(`ideaJam-${id}`,JSON.stringify(data));    
      setShow(false,"Close");
    }
    else {
      setShow(false,"Submit");
    }
  }

  
  const signMessage = async () => {


    // Now we can sign the message with the connected wallet
    const message = "Roasting an Idea For- CR Square Guardian";
    try{
      // console.log("sdk",sdk)
      const signature = await sdk.wallet.sign(message);
      // console.log("Signature ",signature);
      createRatedIdea();
    }
    catch (e){ 
      console.log(e)     
      alert("Please Accept the Signature Request to Submit Your Review");
    }

}

  const handleSubmit = async () => {
    if(comment === "" || comment === null || comment === undefined){
        alert("Please Provide A Comment To Submit")
    }
    else if (rating === 0 || rating === null || rating === undefined){
        alert("Please Provide an Valid Rating to Submit")
    } 
    else{
        try {
          await signMessage();
        } catch (error) {
          console.log("Error:", error);
        }
    }

  };
  const createRatedIdea = async () => {
    try {
      var query = `
      mutation Mutation($input: RatedIdeaJamInput) {
        createRatedIdeaJam(input: $input) {
          comments,
          rating
        }
      }
            `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          'x-power':process.env.REACT_APP_POWER_KEY,
          'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
          'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
          'x-range-name':process.env.REACT_APP_RANGE_NAME
        },
        body: JSON.stringify({
          query,
          variables: {
            input: {
                idea: id,
                reviewer_wallet: walletAddress,
                comments:comment,
                rating: rating
            }
          }
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.createRatedIdeaJam) {
            localStorage.removeItem(`ideaJam-${id}`)
            handleClose("Submit");
            ValidateIdea(true);
            //Clear Local Storage
            }
        });
    } catch (error) {
      console.log(error);
    }
}




  return (
    <div>
      <Offcanvas show={show} onHide={() => handleClose("Close")} placement={"end"} scroll={true} backdrop={true} className = "sidebar-width">
        <Offcanvas.Header closeButton  bsPrefix='sidebar-closebutton' closeVariant='white'>
          <Offcanvas.Title>Roast This Idea </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="offcanvas-body p-4">
                <div className="row" >
                    <div className="col-lg-12 col-md-12 order-2 order-md-0 mt-5 mt-sm-0 mb-5">
                        <div className="section-title">
                            <br />
                                <div>
                                <h5 className="mb-1" style={{height: "150px"}}>
                                    Share your funky roast of the idea right here, and let the rhythm flow.
                                </h5>
                                <br/>
                                <Form.Control as="textarea" placeholder="Enter your comments here.." 
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                name="comments"
                                style={{ height: '100px' }} />
                                <div>
                                <br />
                                <br />
                                <br />
                               
                                <div className='d-flex flex-column align-items-center'>  

                                <Rating
                                    name="simple-controlled"
                                    value={rating}
                                    size="large"
                                    onChange={(event,newValue) => {

                                        setRating(newValue);
                                    }}
                                    />
                                </div>
                              </div>
                            </div>
                                                     
                                
                </div>
                            <div className="col-12 mb-0">
            <div className="mt-5 component-wrapper rounded justify-content-between">
              <div className="p-4" style={{justifyContent: "center",display: "flex"}}>
                
                <button 
                style ={{float:"right"}} onClick={() => handleSubmit()}  
                className="btn btn-primary btn-th">Roast</button> <span style={{paddingRight:"4.5px"}}/>

              </div>
            </div>
          </div>
                        
                    </div>
                </div>
            </div>
        </Offcanvas.Body>
      </Offcanvas>
        <br /><br /><br />
    </div>

  )
}

export default IdeaValidate