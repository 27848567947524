import React,{useEffect,useState} from 'react'
import TinySlider from '../../Components/TinySlider'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { apiURI } from '../../config';


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IdeaCard from './Ideas/IdeaCard';

const TopRated = ({setActiveTab}) => {
  
  // const navigate = useNavigate();
  const [topRatedIdeas,setTopRatedIdeas] =  useState([]);

  const getIdeasData = async () => {
    try {
      var query = `
      query GetTopRatedIdeas($id: ID) {
        getTopRatedIdeas(_id: $id) {
                idea_name
                idea_logo
                one_line_desc
                category {
                  value
                }
                product_type
                whitepaper_link
                twitter_link
                linkedin_link
                liveProduct_link
                _id
        }
      }`;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          'x-power':process.env.REACT_APP_POWER_KEY,
          'x-domain-agent':process.env.REACT_APP_DOMAIN_AGENT,
          'x-strict-origin-name':process.env.REACT_APP_ORIGIN_NAME,
          'x-range-name':process.env.REACT_APP_RANGE_NAME
        },
        body: JSON.stringify({
          query,
          variables: {
            id: "null"
          }
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
            // console.log("Idea 1234" ,data.data.getTopRatedIdeas);
            if (data?.data?.getTopRatedIdeas) {
              setTopRatedIdeas(data.data.getTopRatedIdeas)
            }
        });
    } catch (error) {
      console.log(error);
    }
}


var settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  // slidesToScroll: 1,

  swipeToSlide: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        infinite: true,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: false,
        // infinite: true,
        // dots:true
      },
    },
  ],
};


useEffect(() => {
  getIdeasData();
}, [])



  return (
    <div>
              {/* Ideas */}
              <div class="row pt-0">
              <div class="col-12">
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-cloud"
                          role="tabpanel"
                          aria-labelledby="pills-cloud-tab"
                        >
                          <div class="row pb-4 border-bottom">
                            <div class="mb-0 mt-5" style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                            <h6 >Top Rated Products&nbsp; 💡</h6>
                            <h6 onClick={()=> {setActiveTab("ideas")}} style={{ width:"auto",cursor:'pointer'}}>See More {'>'}</h6>
                            </div>
                            <Slider {...settings}>
                  {topRatedIdeas.map((value, index) => {
                    return (
                      <IdeaCard  data={value}/>
                    );
                  })}
                </Slider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
      {/* Projects */}
      <div class="row pt-0">
                    <div class="col-12">
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-cloud"
                          role="tabpanel"
                          aria-labelledby="pills-cloud-tab"
                        >
                          <div class="row pb-4 border-bottom ">
                            <h6 class="mb-0 mt-5">Top Rated Projects&nbsp; 💼</h6>
                            <div  >
                            <div>
          <Link to="/quiz" className="lock-symbol btn btn-primary btn-th">
            Become a Roarnado to Validate Startups
          </Link>
        </div> 
                            <TinySlider />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
      {/* Deals */}
      {/* <div class="row pt-0">
                    <div class="col-12">
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-cloud"
                          role="tabpanel"
                          aria-labelledby="pills-cloud-tab"
                        >
                          <div class="row pb-4 border-bottom">
                            <h6 class="mb-0 mt-5">TopRated Deals&nbsp; 🤝</h6>
                            <div class="blur">
                            <TinySlider />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

      {/* Milestones */}
                  {/* <div class="row pt-0">
      <div class="col-12">
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-cloud"
                          role="tabpanel"
                          aria-labelledby="pills-cloud-tab"
                        >
                          <div class="row pb-4 border-bottom">
                            <h6 class="mb-0 mt-5">TopRated Milestones&nbsp; 🚩</h6>
                            <div class="blur">
                            <TinySlider />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
    </div>
  )
}

export default TopRated;