import React,{useState} from 'react'

import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton,  TelegramShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, TelegramIcon, LinkedinIcon } from "react-share";

import { Button } from 'react-bootstrap';
import copy from "../assests/icons/copy.png";
import check from "../assests/icons/check.png";

const ShareComponent = ({currentIdea}) => {

    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`📢Hey Friend! Check out my project on the CR Square Guardian Platform and share your valuable rating. \n\nYour ratings will enable a chance for us to pitch the idea to top-notch INVESTORS at Pitch2 by GUARDIAN. \n\n\n Appreciate your support! 👍 https://dashboard.guardian.crsquare.finance/review2earn/idea-jams/${currentIdea}`)
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false)
              }, 2000);
    }

  return (
    <div>
        <FacebookShareButton url={`https://dashboard.guardian.crsquare.finance/review2earn/idea-jams/${currentIdea}`}
            hashtag={["#web3fundraising","#review2earn","#web3"]}
            quote={"Check and rate our project on CR Square Guardian Platform"}
            description={"Check and rate our project on CR Square Guardian Platform"}
            >
            <FacebookIcon size={32} round />
        </FacebookShareButton>
        &nbsp;
        &nbsp;
        <TwitterShareButton
                title={`📢 Hey Friend! Check out my project on the @Cr2_Finance Platform and share your valuable rating. \n\nYour ratings will enable a chance for us to pitch the idea to top-notch INVESTORS at Pitch2 by GUARDIAN. \n\n\n Roast here 👇`}
                url={`https://dashboard.guardian.crsquare.finance/review2earn/idea-jams/${currentIdea}`}
                hashtags={["Review2Earn","Web3Fundraising"]}
                related={["@Cr2_Finance"]}
                >
            <TwitterIcon size={32} round />
            </TwitterShareButton>
            &nbsp;
            &nbsp;
            <WhatsappShareButton
                title={`📢 Hey Friend! Check out my project on the CR Square Guardian Platform and share your valuable rating. \n\nYour ratings will enable a chance for us to pitch the idea to top-notch INVESTORS at Pitch2 by GUARDIAN. \n\n\n Roast here 👉`}
                url={`https://dashboard.guardian.crsquare.finance/review2earn/idea-jams/${currentIdea}`}
                >
            <WhatsappIcon size={32} round />

            </WhatsappShareButton>
            &nbsp;
            &nbsp;
            <TelegramShareButton
               title={`📢 Hey Friend! Check out my project on the CR Square Guardian Platform and share your valuable rating. \n\nYour ratings will enable a chance for us to pitch the idea to top-notch INVESTORS at Pitch2 by GUARDIAN. \n\n\n Appreciate your support! 👍`}
                url={`https://dashboard.guardian.crsquare.finance/review2earn/idea-jams/${currentIdea}`}
                >
                <TelegramIcon size={32} round />
            </TelegramShareButton>
            &nbsp;
            &nbsp;
            <LinkedinShareButton 
                title={"Check and rate our project on CR Square Guardian Platform"}
                summary={"test summary"}
                url={`https://dashboard.guardian.crsquare.finance/review2earn/idea-jams/${currentIdea}`}
                source={`https://dashboard.guardian.crsquare.finance/review2earn/idea-jams/${currentIdea}`}>
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            &nbsp;
            &nbsp;
            {isCopied === true ? 
                            <Button className="maianButn react-share__ShareButton" style={{ borderRadius: '50%' ,width:"32px",height:"32px",padding:"6px",background:'white',}}  data-tip="Copied">
                               <img src={check} alt="" style={{ width: '18px',display:"flex" }}  />
                           </Button>
                            :
                            <Button className="maianButn react-share__ShareButton" style={{ borderRadius: '50%',width:"32px",height:"32px",padding:"6px" }} onClick={copyToClipboard}>
                            <img src={copy} alt="" style={{ width: '18px', display: "flex" }}  />
                            </Button>
                        }
    </div>
  )
}

export default ShareComponent